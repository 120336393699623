import { AppLanguage } from "core/stores/useAppLanguageStore";
import { countryNameFromCode } from "libs/countryNames";
import {
  ProductBySubcategory,
  TranslatedProductBySubcategory,
  UnitInProductBySubcategory,
} from "shared/models/product/types";
import { SearchUnitsByEanQuery } from "shared/queries/productSearchUnit/productSearchUnit.generated";
import { getShelfDetail } from "utils/item";
import { isNullOrUndefined } from "utils/tsHelpers";

import { ProductSearchUnit, TranslatedProductSearchUnit, UnitType } from "./types";

export type SerializeInboundUnitFromSearchResultInput = {
  id: string;
  ean?: string | null;
  productSku: string;
  quantity: number;
  type: string;
  product: {
    imageUrl?: string | null;
    name: string;
    sku?: string | null;
    inventoryEntry: {
      shelfNumber?: string | null;
      stock: { shelf: number };
    };
    countryOfOrigin: {
      code?: string | null;
    };
    bio?: boolean | null;
    numberOfShelfFacings?: number | null;
    isShelvedInHandlingUnits?: boolean | null;
  };
};

export function serializeInboundUnitFromSearchResult(
  unit: SerializeInboundUnitFromSearchResultInput,
): ProductSearchUnit {
  const { shelfNumber } = unit.product.inventoryEntry;
  const shelfDetail = shelfNumber ? getShelfDetail(shelfNumber) : null;

  return {
    id: unit.id,
    ean: unit.ean ?? "",
    productImageUrl: unit.product.imageUrl ?? null,
    productName: unit.product.name,
    productSku: unit.productSku,
    quantity: unit.quantity,
    shelf: unit.product.inventoryEntry.shelfNumber ?? null,
    shelfLetter: shelfDetail?.shelfLetter ?? null,
    shelfNumber: shelfDetail?.shelfNumber ?? null,
    unitType: unit.type as UnitType,
    countryOfOriginCode: unit.product.countryOfOrigin.code?.toUpperCase() ?? null,
    isProductBio: unit.product.bio ?? null,
    numberOfShelfFacings: unit.product.numberOfShelfFacings ?? null,
    isShelvedInHandlingUnits: unit.product.isShelvedInHandlingUnits ?? null,
    stockOnShelf: unit.product.inventoryEntry.stock.shelf ?? 0,
  };
}

export function translatedInboundUnitFromSearchResult(
  data: SearchUnitsByEanQuery,
  appLanguage: AppLanguage = AppLanguage.En,
): TranslatedProductSearchUnit[] {
  return data.searchUnitsByEan.units.map((unit) => {
    const inboundUnit = serializeInboundUnitFromSearchResult(unit);
    return {
      ...inboundUnit,
      countryOfOriginName: countryNameFromCode(appLanguage, inboundUnit.countryOfOriginCode),
    };
  });
}

export function findAndExtractInboundUnitFromProduct(
  product: ProductBySubcategory,
  finderFunction: (p: ProductBySubcategory) => UnitInProductBySubcategory | undefined,
): ProductSearchUnit | null {
  const unitInProduct = finderFunction(product);
  if (isNullOrUndefined(unitInProduct)) {
    return null;
  }
  return {
    id: unitInProduct.id,
    ean: unitInProduct.ean,
    productImageUrl: product.imageUrl,
    productName: product.name,
    productSku: product.sku,
    quantity: unitInProduct.quantity,
    shelf: product.shelfNumber,
    shelfLetter: product.shelfLetter,
    shelfNumber: product.shelfNumber,
    unitType: unitInProduct.unitType,
    countryOfOriginCode: product.countryOfOriginCode,
    isProductBio: product.isBio,
    numberOfShelfFacings: product.numberOfShelfFacings ?? null,
    isShelvedInHandlingUnits: product.isShelvedInHandlingUnits ?? null,
  };
}

export function findTranslatedProductSearchUnit(
  product: TranslatedProductBySubcategory,
  finderFunction: (p: TranslatedProductBySubcategory) => UnitInProductBySubcategory | undefined,
): TranslatedProductSearchUnit | null {
  const unitInProduct = finderFunction(product);
  if (isNullOrUndefined(unitInProduct)) {
    return null;
  }
  return {
    id: unitInProduct.id,
    ean: unitInProduct.ean,
    productImageUrl: product.imageUrl,
    productName: product.name,
    productSku: product.sku,
    quantity: unitInProduct.quantity,
    shelf: product.shelfNumber,
    shelfLetter: product.shelfLetter,
    shelfNumber: product.shelfNumber,
    unitType: unitInProduct.unitType,
    countryOfOriginCode: product.countryOfOriginCode,
    countryOfOriginName: product.countryOfOriginName,
    isProductBio: product.isBio,
    numberOfShelfFacings: product.numberOfShelfFacings ?? null,
    isShelvedInHandlingUnits: product.isShelvedInHandlingUnits ?? null,
  };
}
