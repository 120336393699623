import * as Types from "../../../../__graphql__/types.js";

import { gql } from "@apollo/client";
import * as Apollo from "@apollo/client";
const defaultOptions = {} as const;
export type GetInboundingListsQueryVariables = Types.Exact<{ [key: string]: never }>;

export type GetInboundingListsQuery = {
  __typename?: "Query";
  getInboundingLists: {
    __typename?: "GetInboundingListsResponse";
    lists?: Array<{
      __typename?: "InboundingList";
      id: string;
      sscc: string;
      status: Types.InboundingListStatus;
      name: string;
      creatorId: string;
      createdAt: string;
      products?: Array<{
        __typename?: "InboundingListProduct";
        id: string;
        sku: string;
        quantity: number;
        handlingUnitSize: number;
        imageUrl?: string | null;
      }> | null;
    }> | null;
  };
};

export type GetProductsQueryVariables = Types.Exact<{
  input: Types.GetProductsInput;
}>;

export type GetProductsQuery = {
  __typename?: "Query";
  getProducts: {
    __typename?: "GetProductsResponse";
    products: Array<{
      __typename?: "IProduct";
      name: string;
      sku: string;
      imageUrl?: string | null;
      bio?: boolean | null;
      countryOfOrigin: { __typename?: "CountryOfOrigin"; code?: string | null };
      inventoryEntry: { __typename?: "InventoryEntry"; shelfNumber?: string | null };
    }>;
  };
};

export type GetProductsFromClaimedListQueryVariables = Types.Exact<{ [key: string]: never }>;

export type GetProductsFromClaimedListQuery = {
  __typename?: "Query";
  getProductsFromClaimedList: {
    __typename?: "GetListClaimedProductsResponse";
    id?: string | null;
    name?: string | null;
    products: Array<{
      __typename?: "GetClaimedProducts";
      id: string;
      name: string;
      sku: string;
      imageUrl?: string | null;
      bio?: boolean | null;
      numberOfShelfFacings?: number | null;
      isShelvedInHandlingUnits?: boolean | null;
      quantity: number;
      handlingUnitSize: number;
      status: Types.ListProductStatus;
      countryOfOrigin: { __typename?: "CountryOfOrigin"; code?: string | null };
      inventoryEntry: {
        __typename?: "InventoryEntry";
        shelfNumber?: string | null;
        stock: { __typename?: "Stock"; shelf: number };
      };
    }>;
  };
};

export type CreateInboundingListMutationVariables = Types.Exact<{
  input: Types.CreateInboundingListInput;
}>;

export type CreateInboundingListMutation = {
  __typename?: "Mutation";
  createInboundingList: { __typename?: "CreateInboundingListResponse"; id: string };
};

export type AddProductsToInboundingListMutationVariables = Types.Exact<{
  input: Types.AddProductsToInboundingListInput;
}>;

export type AddProductsToInboundingListMutation = {
  __typename?: "Mutation";
  addProductsToInboundingList: {
    __typename?: "AddProductsToInboundingListResponse";
    success: boolean;
  };
};

export type ClaimInboundingListMutationVariables = Types.Exact<{
  input: Types.ClaimInboundingListInput;
}>;

export type ClaimInboundingListMutation = {
  __typename?: "Mutation";
  claimInboundingList: { __typename?: "ClaimInboundingListResponse"; success: boolean };
};

export type InboundProductMutationVariables = Types.Exact<{
  input: Types.InboundProductInput;
}>;

export type InboundProductMutation = {
  __typename?: "Mutation";
  inboundProduct: { __typename?: "InboundProductResponse"; success: boolean };
};

export type ReleaseInboundingListMutationVariables = Types.Exact<{
  input: Types.ReleaseInboundingListInput;
}>;

export type ReleaseInboundingListMutation = {
  __typename?: "Mutation";
  releaseInboundingList: { __typename?: "ReleaseInboundingListResponse"; success: boolean };
};

export const GetInboundingListsDocument = gql`
  query getInboundingLists {
    getInboundingLists {
      lists {
        id
        products {
          id
          sku
          quantity
          handlingUnitSize
          imageUrl
        }
        sscc
        status
        name
        creatorId
        createdAt
      }
    }
  }
`;

/**
 * __useGetInboundingListsQuery__
 *
 * To run a query within a React component, call `useGetInboundingListsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetInboundingListsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetInboundingListsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetInboundingListsQuery(
  baseOptions?: Apollo.QueryHookOptions<GetInboundingListsQuery, GetInboundingListsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetInboundingListsQuery, GetInboundingListsQueryVariables>(
    GetInboundingListsDocument,
    options,
  );
}
export function useGetInboundingListsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetInboundingListsQuery,
    GetInboundingListsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetInboundingListsQuery, GetInboundingListsQueryVariables>(
    GetInboundingListsDocument,
    options,
  );
}
export type GetInboundingListsQueryHookResult = ReturnType<typeof useGetInboundingListsQuery>;
export type GetInboundingListsLazyQueryHookResult = ReturnType<
  typeof useGetInboundingListsLazyQuery
>;
export type GetInboundingListsQueryResult = Apollo.QueryResult<
  GetInboundingListsQuery,
  GetInboundingListsQueryVariables
>;
export const GetProductsDocument = gql`
  query getProducts($input: GetProductsInput!) {
    getProducts(input: $input) {
      products {
        name
        sku
        imageUrl
        bio
        countryOfOrigin {
          code
        }
        inventoryEntry {
          shelfNumber
        }
      }
    }
  }
`;

/**
 * __useGetProductsQuery__
 *
 * To run a query within a React component, call `useGetProductsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProductsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProductsQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetProductsQuery(
  baseOptions: Apollo.QueryHookOptions<GetProductsQuery, GetProductsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetProductsQuery, GetProductsQueryVariables>(GetProductsDocument, options);
}
export function useGetProductsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetProductsQuery, GetProductsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetProductsQuery, GetProductsQueryVariables>(
    GetProductsDocument,
    options,
  );
}
export type GetProductsQueryHookResult = ReturnType<typeof useGetProductsQuery>;
export type GetProductsLazyQueryHookResult = ReturnType<typeof useGetProductsLazyQuery>;
export type GetProductsQueryResult = Apollo.QueryResult<
  GetProductsQuery,
  GetProductsQueryVariables
>;
export const GetProductsFromClaimedListDocument = gql`
  query getProductsFromClaimedList {
    getProductsFromClaimedList {
      id
      name
      products {
        id
        name
        sku
        imageUrl
        bio
        countryOfOrigin {
          code
        }
        inventoryEntry {
          shelfNumber
          stock {
            shelf
          }
        }
        numberOfShelfFacings
        isShelvedInHandlingUnits
        quantity
        handlingUnitSize
        status
      }
    }
  }
`;

/**
 * __useGetProductsFromClaimedListQuery__
 *
 * To run a query within a React component, call `useGetProductsFromClaimedListQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProductsFromClaimedListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProductsFromClaimedListQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetProductsFromClaimedListQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetProductsFromClaimedListQuery,
    GetProductsFromClaimedListQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetProductsFromClaimedListQuery, GetProductsFromClaimedListQueryVariables>(
    GetProductsFromClaimedListDocument,
    options,
  );
}
export function useGetProductsFromClaimedListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetProductsFromClaimedListQuery,
    GetProductsFromClaimedListQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetProductsFromClaimedListQuery,
    GetProductsFromClaimedListQueryVariables
  >(GetProductsFromClaimedListDocument, options);
}
export type GetProductsFromClaimedListQueryHookResult = ReturnType<
  typeof useGetProductsFromClaimedListQuery
>;
export type GetProductsFromClaimedListLazyQueryHookResult = ReturnType<
  typeof useGetProductsFromClaimedListLazyQuery
>;
export type GetProductsFromClaimedListQueryResult = Apollo.QueryResult<
  GetProductsFromClaimedListQuery,
  GetProductsFromClaimedListQueryVariables
>;
export const CreateInboundingListDocument = gql`
  mutation createInboundingList($input: CreateInboundingListInput!) {
    createInboundingList(input: $input) {
      id
    }
  }
`;
export type CreateInboundingListMutationFn = Apollo.MutationFunction<
  CreateInboundingListMutation,
  CreateInboundingListMutationVariables
>;

/**
 * __useCreateInboundingListMutation__
 *
 * To run a mutation, you first call `useCreateInboundingListMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateInboundingListMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createInboundingListMutation, { data, loading, error }] = useCreateInboundingListMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateInboundingListMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateInboundingListMutation,
    CreateInboundingListMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateInboundingListMutation, CreateInboundingListMutationVariables>(
    CreateInboundingListDocument,
    options,
  );
}
export type CreateInboundingListMutationHookResult = ReturnType<
  typeof useCreateInboundingListMutation
>;
export type CreateInboundingListMutationResult =
  Apollo.MutationResult<CreateInboundingListMutation>;
export type CreateInboundingListMutationOptions = Apollo.BaseMutationOptions<
  CreateInboundingListMutation,
  CreateInboundingListMutationVariables
>;
export const AddProductsToInboundingListDocument = gql`
  mutation addProductsToInboundingList($input: AddProductsToInboundingListInput!) {
    addProductsToInboundingList(input: $input) {
      success
    }
  }
`;
export type AddProductsToInboundingListMutationFn = Apollo.MutationFunction<
  AddProductsToInboundingListMutation,
  AddProductsToInboundingListMutationVariables
>;

/**
 * __useAddProductsToInboundingListMutation__
 *
 * To run a mutation, you first call `useAddProductsToInboundingListMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddProductsToInboundingListMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addProductsToInboundingListMutation, { data, loading, error }] = useAddProductsToInboundingListMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddProductsToInboundingListMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AddProductsToInboundingListMutation,
    AddProductsToInboundingListMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    AddProductsToInboundingListMutation,
    AddProductsToInboundingListMutationVariables
  >(AddProductsToInboundingListDocument, options);
}
export type AddProductsToInboundingListMutationHookResult = ReturnType<
  typeof useAddProductsToInboundingListMutation
>;
export type AddProductsToInboundingListMutationResult =
  Apollo.MutationResult<AddProductsToInboundingListMutation>;
export type AddProductsToInboundingListMutationOptions = Apollo.BaseMutationOptions<
  AddProductsToInboundingListMutation,
  AddProductsToInboundingListMutationVariables
>;
export const ClaimInboundingListDocument = gql`
  mutation claimInboundingList($input: ClaimInboundingListInput!) {
    claimInboundingList(input: $input) {
      success
    }
  }
`;
export type ClaimInboundingListMutationFn = Apollo.MutationFunction<
  ClaimInboundingListMutation,
  ClaimInboundingListMutationVariables
>;

/**
 * __useClaimInboundingListMutation__
 *
 * To run a mutation, you first call `useClaimInboundingListMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useClaimInboundingListMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [claimInboundingListMutation, { data, loading, error }] = useClaimInboundingListMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useClaimInboundingListMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ClaimInboundingListMutation,
    ClaimInboundingListMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<ClaimInboundingListMutation, ClaimInboundingListMutationVariables>(
    ClaimInboundingListDocument,
    options,
  );
}
export type ClaimInboundingListMutationHookResult = ReturnType<
  typeof useClaimInboundingListMutation
>;
export type ClaimInboundingListMutationResult = Apollo.MutationResult<ClaimInboundingListMutation>;
export type ClaimInboundingListMutationOptions = Apollo.BaseMutationOptions<
  ClaimInboundingListMutation,
  ClaimInboundingListMutationVariables
>;
export const InboundProductDocument = gql`
  mutation inboundProduct($input: InboundProductInput!) {
    inboundProduct(input: $input) {
      success
    }
  }
`;
export type InboundProductMutationFn = Apollo.MutationFunction<
  InboundProductMutation,
  InboundProductMutationVariables
>;

/**
 * __useInboundProductMutation__
 *
 * To run a mutation, you first call `useInboundProductMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInboundProductMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [inboundProductMutation, { data, loading, error }] = useInboundProductMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useInboundProductMutation(
  baseOptions?: Apollo.MutationHookOptions<InboundProductMutation, InboundProductMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<InboundProductMutation, InboundProductMutationVariables>(
    InboundProductDocument,
    options,
  );
}
export type InboundProductMutationHookResult = ReturnType<typeof useInboundProductMutation>;
export type InboundProductMutationResult = Apollo.MutationResult<InboundProductMutation>;
export type InboundProductMutationOptions = Apollo.BaseMutationOptions<
  InboundProductMutation,
  InboundProductMutationVariables
>;
export const ReleaseInboundingListDocument = gql`
  mutation releaseInboundingList($input: ReleaseInboundingListInput!) {
    releaseInboundingList(input: $input) {
      success
    }
  }
`;
export type ReleaseInboundingListMutationFn = Apollo.MutationFunction<
  ReleaseInboundingListMutation,
  ReleaseInboundingListMutationVariables
>;

/**
 * __useReleaseInboundingListMutation__
 *
 * To run a mutation, you first call `useReleaseInboundingListMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useReleaseInboundingListMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [releaseInboundingListMutation, { data, loading, error }] = useReleaseInboundingListMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useReleaseInboundingListMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ReleaseInboundingListMutation,
    ReleaseInboundingListMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<ReleaseInboundingListMutation, ReleaseInboundingListMutationVariables>(
    ReleaseInboundingListDocument,
    options,
  );
}
export type ReleaseInboundingListMutationHookResult = ReturnType<
  typeof useReleaseInboundingListMutation
>;
export type ReleaseInboundingListMutationResult =
  Apollo.MutationResult<ReleaseInboundingListMutation>;
export type ReleaseInboundingListMutationOptions = Apollo.BaseMutationOptions<
  ReleaseInboundingListMutation,
  ReleaseInboundingListMutationVariables
>;
