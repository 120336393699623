import { useEffect, useRef, createRef, useCallback } from "react";

import { isNotNullNorUndefined } from "utils/tsHelpers";

export function useScrollToActiveItem({
  activeItemId,
  listItemIds,
}: {
  activeItemId: string | null;
  listItemIds: string[];
}) {
  const scrollContainerRef = useRef<HTMLDivElement | null>(null);
  const itemsRef = useRef<React.RefObject<HTMLDivElement>[]>([]);

  useEffect(() => {
    itemsRef.current = listItemIds.map(
      (_, i) => itemsRef.current[i] || createRef<HTMLDivElement>(),
    );
  }, [listItemIds]);

  const scrollToActiveItem = useCallback(() => {
    const activeItemIndex = listItemIds.indexOf(activeItemId as string);
    const activeItemRef = itemsRef.current[activeItemIndex];

    if (
      isNotNullNorUndefined(activeItemRef?.current) &&
      isNotNullNorUndefined(scrollContainerRef.current)
    ) {
      const { offsetTop } = activeItemRef.current;
      scrollContainerRef.current.scrollTo({
        top: offsetTop - 12,
        behavior: "smooth",
      });
    }
  }, [activeItemId, listItemIds]);

  useEffect(() => {
    scrollToActiveItem();
  }, [activeItemId, listItemIds, scrollToActiveItem]);

  return {
    scrollContainerRef,
    itemsRef,
  };
}
