import { useCallback } from "react";

import { ApolloError, useApolloClient } from "@apollo/client";
import { isEqual } from "lodash";

import { DespatchAdviceItem, InventoryChangeReason, MultipleDesadv } from "__graphql__/types";
import { EppoFeatureFlags } from "core/types/flags";
import {
  GetMultipleDesadvsRolliIdDocument,
  GetMultipleDesadvsRolliIdQuery,
  GetMultipleDesadvsRolliIdQueryVariables,
} from "flows/Inbound/queries/despatchAdvice/despatchAdvice.generated";
import {
  GetProductsStockDocument,
  GetProductsStockQuery,
  GetProductsStockQueryVariables,
  GetUnitsSizesDocument,
  GetUnitsSizesQuery,
  GetUnitsSizesQueryVariables,
} from "flows/Inbound/queries/product/product.generated";
import { useAnalytics } from "shared/hooks/useAnalytics";
import { useEppoFeatureFlagProvider } from "shared/hooks/useEppoFeatureFlag";
import {
  UpdateProductStockByDeltaAndMultipleReasonsDocument,
  UpdateProductStockByDeltaAndMultipleReasonsMutation,
  UpdateProductStockByDeltaAndMultipleReasonsMutationVariables,
} from "shared/queries/inventoryEntry/inventoryEntry.generated";
import { reportErrorsToDDAndThrow } from "utils/datadog";
import { asyncTryCatchWithErrorChecking } from "utils/error";

import { useDespatchAdviceCallback } from "../../hooks/useDespatchAdvice/useDespatchAdvice";
import {
  ListVerificationCheckTypeKeys,
  SerializedListVerificationCheck,
} from "../../models/listVerificationCheck/types";
import {
  InboundMachineContext,
  GenerateListVerificationChecks,
  LoadOnShelfStockLevels,
  UpdateInboundUnitProductStock,
  LoadingHandlingUnitSizes,
  FetchMultipleDesadvs,
} from "./types";
import {
  extractInboundPlan,
  getMatchedtDesadvId,
  getUniqueHandlingUnitSizes,
  sumStockUpdatePlanTotal,
} from "./utils";

export function useInboundServiceImplems() {
  const client = useApolloClient();
  const { isFeatureEnabled: isDesadvBasedInboundingEnabled } = useEppoFeatureFlagProvider(
    EppoFeatureFlags.DESADV_BASED_INBOUNDING,
  );
  const { isFeatureEnabled: isListVerificationHUCheckEnabled } = useEppoFeatureFlagProvider(
    EppoFeatureFlags.LIST_VERIFICATION_HU_CHECK,
  );

  const { sendSegmentTrackEvent } = useAnalytics();
  const getSelectDespatchAdviceBySku = useDespatchAdviceCallback();

  const getAdditionalMetaData = useCallback(
    (
      sku: string,
      despatchAdviceItems: Record<string, DespatchAdviceItem[]>,
      ean?: string,
      inboundingMethod?: string,
    ) => {
      if (!isDesadvBasedInboundingEnabled) return {};
      const despatchAdviceItem = getSelectDespatchAdviceBySku(sku, despatchAdviceItems);
      const desadv = getMatchedtDesadvId(sku, despatchAdviceItems);
      const isMethodScan = inboundingMethod === "scan";
      const scannedEAN = isMethodScan ? ean : null;
      if (despatchAdviceItem) {
        return {
          desadv,
          expectedQuantity: despatchAdviceItem.totalQuantity.toString(),
          inboundingMethod,
          inboundingUnit: despatchAdviceItem.unitType,
          scannedEAN,
        };
      }
      return isMethodScan
        ? {
            inboundingMethod,
            scannedEAN,
          }
        : {};
    },
    [getSelectDespatchAdviceBySku, isDesadvBasedInboundingEnabled],
  );

  const updateInboundUnitProductStock: UpdateInboundUnitProductStock = async (
    context: InboundMachineContext,
    { sku }: { sku: string },
  ): Promise<string> => {
    return reportErrorsToDDAndThrow("updating inbound unit product stock by reasons", async () => {
      const { deliverySSCC, droppingListId, despatchAdviceItems, inboundUnitsMap } = context;
      const inboundUnitStockUpdateData = context.inboundUnitsStockUpdateState[sku];
      const inboundUnitDisplayState = context.inboundUnitsDisplayStates[sku];
      if (!inboundUnitStockUpdateData) {
        throw new Error("missing stock update plan");
      }
      if (!inboundUnitDisplayState) {
        throw new Error("missing display state");
      }
      if (!droppingListId) {
        throw new Error("missing dropping list ID");
      }

      const sortedStockUpdatePlan = [...inboundUnitStockUpdateData.stockUpdatePlan].sort(
        (stockUpdateA, stockUpdateB) => {
          return stockUpdateB.quantityDelta - stockUpdateA.quantityDelta;
        },
      );

      const filteredAndSortedUpdatePlan = sortedStockUpdatePlan.filter(
        (plan) => plan.quantityDelta !== 0,
      );

      const [quantityInbounded, quantityExpired, quantityDamaged] = [
        InventoryChangeReason.inbound_goods_received,
        InventoryChangeReason.outbound_delivery_product_expired,
        InventoryChangeReason.outbound_delivery_product_damaged,
      ].map(
        (inventoryChangeReason) =>
          filteredAndSortedUpdatePlan.find(({ reason }) => reason === inventoryChangeReason)
            ?.quantityDelta ?? 0,
      );

      if (quantityInbounded === 0) {
        throw new Error("stock updates must contain an inbounding");
      }
      const { shelf, ean, inboundingMethod } = inboundUnitsMap[sku];

      const metadata = getAdditionalMetaData(sku, despatchAdviceItems, ean, inboundingMethod);

      try {
        await client.mutate<
          UpdateProductStockByDeltaAndMultipleReasonsMutation,
          UpdateProductStockByDeltaAndMultipleReasonsMutationVariables
        >({
          mutation: UpdateProductStockByDeltaAndMultipleReasonsDocument,
          variables: {
            updateProductStockByDeltaAndMultipleReasonsInput: {
              sku,
              stockUpdates: filteredAndSortedUpdatePlan.map(({ quantityDelta, reason }) => ({
                quantityDelta,
                reason,
                transactionId: `${droppingListId}-${sku}-${reason}`,
                sscc: deliverySSCC ?? null,
                ...metadata,
              })),
            },
          },
        });

        sendSegmentTrackEvent("inboundProgressed", {
          action: "product_dropped",
          quantity: quantityInbounded > 0 ? quantityInbounded : 0,
          dropping_list_id: droppingListId,
          product_sku: sku,
          is_handling_unit: inboundUnitDisplayState.unitSizeForDisplay > 1,
          sscc: deliverySSCC ?? null,
          shelf_number: shelf,
        });
        if (quantityExpired + quantityDamaged !== 0) {
          sendSegmentTrackEvent("outboundProgressed", {
            action: "product_outbounded",
            quantity_expired: -quantityExpired,
            quantity_damaged: -quantityDamaged,
            quantity_perished: 0,
            quantity_received: quantityInbounded,
            dropping_list_id: droppingListId,
            product_sku: sku,
            sscc: deliverySSCC ?? null,
            shelf_number: shelf,
          });
        }

        return sku;
      } catch (error) {
        if (
          error instanceof ApolloError &&
          error?.graphQLErrors?.[0]?.message ===
            "InventoryEntry transaction has already been processed"
        ) {
          return sku;
        }
        if (
          error instanceof ApolloError &&
          error?.graphQLErrors?.[0]?.extensions?.code === "INVENTORY_ENTRY_NOT_FOUND"
        ) {
          throw new Error("productNotAssignedForTheHub");
        }
        if (
          error instanceof ApolloError &&
          error?.graphQLErrors?.[0]?.extensions?.code ===
            "INVENTORY_ENTRY_UNABLE_CREATE_SKU_HUB_ASSOCIATION"
        ) {
          throw new Error("unableToCreateSkuHubAssociation");
        }
        throw error;
      }
    });
  };

  const loadOnShelfStockLevels: LoadOnShelfStockLevels = async (
    context: InboundMachineContext,
  ): Promise<Record<string, number>> => {
    return reportErrorsToDDAndThrow("loading stock on shelf for dropping list", async () => {
      const skusOnTheList = Object.keys(context.inboundUnitsMap);
      const skusToFetchStockLevelsFor = skusOnTheList.filter(
        (sku) => !context.inboundUnitsStockUpdateState[sku].stockUpdated,
      );

      if (skusToFetchStockLevelsFor.length === 0) {
        return Promise.resolve({});
      }

      const {
        data: {
          getProducts: { products },
        },
      } = await client.query<GetProductsStockQuery, GetProductsStockQueryVariables>({
        query: GetProductsStockDocument,
        variables: { input: { skus: skusToFetchStockLevelsFor } },
        fetchPolicy: "network-only",
      });

      const result: Record<string, number> = {};
      products.forEach((product) => {
        result[product.sku] = product.inventoryEntry.stock.shelf;
      });
      return result;
    });
  };

  const getHandlingUnitSizes = (skus: string[]) => {
    return client.query<GetUnitsSizesQuery, GetUnitsSizesQueryVariables>({
      query: GetUnitsSizesDocument,
      variables: { input: { skus } },
      fetchPolicy: "network-only",
    });
  };

  const loadingHandlingUnitSizes: LoadingHandlingUnitSizes = async (
    skus: string[],
  ): Promise<Record<string, number[]>> => {
    if (!isListVerificationHUCheckEnabled) return {};
    const response = await asyncTryCatchWithErrorChecking(
      () => getHandlingUnitSizes(skus),
      () => {},
    );
    const products = response?.data?.getProducts?.products;
    if (!products) return {};
    return products.reduce((result, product) => {
      result[product.sku] = getUniqueHandlingUnitSizes(product?.units);
      return result;
    }, {} as Record<string, number[]>);
  };

  const generateListVerificationChecks: GenerateListVerificationChecks = async (
    context: InboundMachineContext,
  ): Promise<SerializedListVerificationCheck[]> => {
    const checkMatches = (props: Partial<SerializedListVerificationCheck>) => {
      return (check: SerializedListVerificationCheck): boolean =>
        (Object.keys(props) as (keyof SerializedListVerificationCheck)[]).every((propKey) =>
          isEqual(props[propKey], check[propKey]),
        );
    };

    const checkDoesNotMatch =
      (props: Partial<SerializedListVerificationCheck>) =>
      (check: SerializedListVerificationCheck) =>
        !checkMatches(props)(check);

    const getNextMultipleOfHUSize = (quantity: number, handlingUnitSize: number) => {
      return Math.ceil(quantity / handlingUnitSize) * handlingUnitSize;
    };

    let newChecks: SerializedListVerificationCheck[] = [...context.listVerificationChecks];

    // if there's a previous round of checks that has been completed, they can be set to isActive: false
    if (newChecks.filter((c) => c.isActive).every((c) => c.isCompleted)) {
      newChecks = newChecks.map((check) => ({
        ...check,
        isActive: false,
      }));
    }

    const skusIninboundUnitsStockUpdateState = Object.keys(context.inboundUnitsStockUpdateState);
    const handlingUnitSizes = await loadingHandlingUnitSizes(skusIninboundUnitsStockUpdateState);

    skusIninboundUnitsStockUpdateState.forEach((sku) => {
      const { stockUpdatePlan, stockUpdated } = context.inboundUnitsStockUpdateState[sku];
      const inboundPlan = extractInboundPlan(stockUpdatePlan);
      if (!inboundPlan) {
        return;
      }
      // Once the user updates the stock by inbounding the product, we should not ask the user to verify the quantity again.
      if (stockUpdated) {
        return;
      }

      const { quantityDelta } = inboundPlan;
      const despatchAdviceItem = getSelectDespatchAdviceBySku(sku, context.despatchAdviceItems);
      if (despatchAdviceItem) {
        return;
      }

      // generate checks of type InboundQuantityOfOne
      if (quantityDelta === 1) {
        const checkType = ListVerificationCheckTypeKeys.InboundQuantityOfOne;
        if (context.listVerificationChecks.find(checkMatches({ type: checkType, sku }))) {
          return;
        }
        // This removes records of previously completed (or open) checks for the same SKU,
        // but with a different quantity. This is so that if you change the quantity again to the *original*
        // quantity, we won't skip the check because it's already completed.
        newChecks = newChecks.filter(checkDoesNotMatch({ sku }));
        newChecks.push({
          key: `${checkType}-${sku}`,
          sku,
          type: checkType,
          isCompleted: false,
          isActive: true,
          data: {},
        });
        return;
      }

      // generate checks of type LargeInboundQuantity
      if (quantityDelta > 50) {
        const checkType = ListVerificationCheckTypeKeys.LargeInboundQuantity;
        if (
          context.listVerificationChecks.find(
            checkMatches({ type: checkType, sku, data: { quantity: quantityDelta } }),
          )
        ) {
          return;
        }
        // This removes records of previously completed (or open) checks for the same SKU,
        // but with a different quantity. This is so that if you change the quantity again to the *original*
        // quantity, we won't skip the check because it's already completed.
        newChecks = newChecks.filter(checkDoesNotMatch({ sku }));
        newChecks.push({
          key: `${checkType}-${sku}-${quantityDelta}`,
          sku,
          type: checkType,
          isCompleted: false,
          isActive: true,
          data: { quantity: quantityDelta },
        });
        return;
      }

      const handlingUnitSizesForSku = handlingUnitSizes[sku];

      // generate checks of type HandlingUnitQuantityMismatch
      if (handlingUnitSizesForSku?.length) {
        const hasNonDivisibleSize = handlingUnitSizesForSku.every(
          (size) => quantityDelta % size !== 0,
        );
        const stockUpdatePlanTotal = sumStockUpdatePlanTotal(stockUpdatePlan);
        const isOutbounded = stockUpdatePlanTotal === 0;
        if (hasNonDivisibleSize && !isOutbounded) {
          const checkType = ListVerificationCheckTypeKeys.HandlingUnitQuantityMismatch;
          const totalOutboundedQuantity = quantityDelta - stockUpdatePlanTotal;
          const expected =
            handlingUnitSizesForSku.length > 1
              ? undefined
              : getNextMultipleOfHUSize(quantityDelta, handlingUnitSizesForSku[0]) -
                totalOutboundedQuantity;
          if (
            context.listVerificationChecks.find(
              checkMatches({
                type: checkType,
                sku,
                data: { quantity: stockUpdatePlanTotal, expected },
              }),
            )
          ) {
            return;
          }
          // This removes records of previously completed (or open) checks for the same SKU,
          // but with a different quantity. This is so that if you change the quantity again to the *original*
          // quantity, we won't skip the check because it's already completed.
          newChecks = newChecks.filter(checkDoesNotMatch({ sku }));
          newChecks.push({
            key: `${checkType}-${sku}-${stockUpdatePlanTotal}`,
            sku,
            type: checkType,
            isCompleted: false,
            isActive: true,
            data: { quantity: stockUpdatePlanTotal, expected },
          });
          return;
        }
      }

      newChecks = newChecks.filter(checkDoesNotMatch({ sku }));
    });

    // remove checks for SKUs that are no longer on the list
    newChecks = newChecks.filter(({ sku }) => !!context.inboundUnitsMap[sku]);

    return Promise.resolve(newChecks);
  };

  const fetchMultipleDesadvs: FetchMultipleDesadvs = async (
    context: InboundMachineContext,
  ): Promise<MultipleDesadv[] | null> => {
    if (!isDesadvBasedInboundingEnabled || !context.deliverySSCC) {
      return Promise.resolve(null);
    }
    const result = await client.query<
      GetMultipleDesadvsRolliIdQuery,
      GetMultipleDesadvsRolliIdQueryVariables
    >({
      query: GetMultipleDesadvsRolliIdDocument,
      variables: { input: { rolliID: context.deliverySSCC } },
      fetchPolicy: "network-only",
    });
    const multipleDesadvs = result.data.getMultipleDesadvsRolliID.despatchAdvices;
    if (!multipleDesadvs) return Promise.resolve(null);
    return multipleDesadvs;
  };

  return {
    getAdditionalMetaData,
    updateInboundUnitProductStock,
    loadOnShelfStockLevels,
    generateListVerificationChecks,
    fetchMultipleDesadvs,
  };
}
