import { useState, useCallback } from "react";

import { isApolloError } from "@apollo/client";
import { datadogRum } from "@datadog/browser-rum";
import { useNavigate } from "react-router";
import shallow from "zustand/shallow";

import { CollaborativeInboundUpdateState, PageName } from "analytics/events";
import { routes } from "config/routes";
import { useEmployeeStore } from "core/stores/useEmployeeStore";
import { useAnalytics } from "shared/hooks/useAnalytics";
import { useCustomToast } from "shared/hooks/useCustomToast";
import { formatEmployeeName } from "utils/formats";
import { isNullOrUndefined } from "utils/tsHelpers";

import { useInboundEventTracker } from "../hooks/useInboundEventTracker/useInboundEventTracker";
import {
  useAddProductsToInboundingListMutation,
  useCreateInboundingListMutation,
} from "../queries/collaborativeInbound/collaborativeInbound.generated";
import { useGetMultipleDesadvsRolliIdLazyQuery } from "../queries/despatchAdvice/despatchAdvice.generated";
import { DeliveryType, InboundStoreContext } from "./inboundStore/types";
import { useInboundStore } from "./inboundStore/useInboundStore";
import { sumStockUpdatePlanTotal } from "./inboundStore/utils";

const CREATE_SHARED_LIST_ERROR_TOAST_ID = "create_shared_list_error_toast";
const CREATE_SHARED_LIST_SUCCESS_TOAST_ID = "create_shared_list_success_toast";
const ADD_ITEMS_SHARED_LIST_ERROR_TOAST_ID = "add_items_shared_list_error_toast";
const ADD_ITEMS_SHARED_LIST_SUCCESS_TOAST_ID = "add_items_shared_list_success_toast";

const formatSharedListName = (firstName: string, lastName: string) => {
  const formattedName = formatEmployeeName(firstName, lastName);
  const currentTimeInGerman = new Date().toLocaleTimeString("de-DE").slice(0, 5);
  return `${formattedName}'s list - ${currentTimeInGerman}`;
};

const getInboundUnitsStockUpdates = ({
  inboundUnitsSortedByDate,
  inboundUnitsStockUpdates,
  inboundUnitsDisplayStates,
}: Pick<
  InboundStoreContext,
  "inboundUnitsStockUpdates" | "inboundUnitsSortedByDate" | "inboundUnitsDisplayStates"
>) => {
  return inboundUnitsSortedByDate.map((sku) => {
    const handlingUnitSize = inboundUnitsDisplayStates[sku]?.unitSizeForDisplay || 1;
    const stockUpdatePlanTotal = sumStockUpdatePlanTotal(
      inboundUnitsStockUpdates[sku]?.stockUpdatePlan,
    );
    return {
      sku,
      quantity: stockUpdatePlanTotal,
      handlingUnitSize,
    };
  });
};

export const useSharedListCreator = () => {
  const navigate = useNavigate();
  const { showToastUI } = useCustomToast();
  const { sendInboundStateUpdate } = useInboundEventTracker();
  const { sendSegmentTrackEvent } = useAnalytics();
  const [isCreatingList, setIsCreatingList] = useState(false);
  const [isAddingItems, setIsAddingItems] = useState(false);

  const {
    deliveryType,
    deliverySSCC,
    sharedListId,
    inboundUnitsSortedByDate,
    inboundUnitsStockUpdates,
    inboundUnitsDisplayStates,
    setDeliverySSCC,
    setSharedListName,
    setSharedListId,
    setInboundUIState,
    resetToInitialState,
  } = useInboundStore(
    (state) => ({
      deliveryType: state.deliveryType,
      deliverySSCC: state.deliverySSCC,
      sharedListId: state.sharedListId,
      inboundUnitsSortedByDate: state.inboundUnitsSortedByDate,
      inboundUnitsStockUpdates: state.inboundUnitsStockUpdates,
      inboundUnitsDisplayStates: state.inboundUnitsDisplayStates,
      setDeliverySSCC: state.setDeliverySSCC,
      setSharedListName: state.setSharedListName,
      setSharedListId: state.setSharedListId,
      setInboundUIState: state.setInboundUIState,
      resetToInitialState: state.resetToInitialState,
    }),
    shallow,
  );

  const { firstName, lastName } = useEmployeeStore(
    (state) => ({
      firstName: state.firstName,
      lastName: state.lastName,
    }),
    shallow,
  );

  const listName = formatSharedListName(firstName, lastName);

  const [fetchDesadvs] = useGetMultipleDesadvsRolliIdLazyQuery({
    onError: (e) => {
      if (
        isApolloError(e) &&
        e.graphQLErrors?.[0]?.extensions?.code === "DESPATCH_ADVICE_NOT_FOUND"
      ) {
        setInboundUIState({ isDespatchAdviceModalVisible: true });
      } else {
        const errorMessage = e instanceof Error ? e.message : "Unknown error";
        datadogRum.addError("Create Shared List Error", { errorMessage });
      }
    },
  });

  const [createSharedListMutation] = useCreateInboundingListMutation({
    onCompleted: (data) => {
      const listId = data?.createInboundingList?.id || null;
      setSharedListName(listName);
      setSharedListId(listId);
      showToastUI({
        status: "success",
        id: CREATE_SHARED_LIST_SUCCESS_TOAST_ID,
        title: "create_shared_list_success_toast",
      });
      navigate(routes.inbound.preDropping);
    },
  });

  const [addItemsToPreDroppingListMutation] = useAddProductsToInboundingListMutation({
    onCompleted: () => {
      resetToInitialState();
      showToastUI({
        status: "success",
        id: ADD_ITEMS_SHARED_LIST_SUCCESS_TOAST_ID,
        title: "add_items_shared_list_success_toast",
      });
      navigate(routes.inbound.root);
    },
  });

  const createSharedList = useCallback(
    async (inboundingType: DeliveryType | null, sscc?: string) => {
      setIsCreatingList(true);
      setDeliverySSCC(sscc || null);
      try {
        if (!isNullOrUndefined(sscc)) {
          const { data } = await fetchDesadvs({ variables: { input: { rolliID: sscc } } });
          // If the fetch fails or there's no data, return early, Do not proceed with creating the shared list
          if (!data) return;
        }
        const { data: sharedListData } = await createSharedListMutation({
          variables: { input: { name: listName, sscc } },
        });
        sendInboundStateUpdate({
          state: CollaborativeInboundUpdateState.ListPreparationStarted,
          droppingListId: sharedListData?.createInboundingList?.id,
          sscc,
          inboundingType,
        });
      } catch (error) {
        showToastUI({
          id: CREATE_SHARED_LIST_ERROR_TOAST_ID,
          title: "create-shared-list-error-toast",
        });
        const errorMessage = error instanceof Error ? error.message : "Unknown error";
        datadogRum.addError("Create Shared List Error", { errorMessage });
      } finally {
        setIsCreatingList(false);
      }
    },
    [
      setDeliverySSCC,
      createSharedListMutation,
      listName,
      sendInboundStateUpdate,
      fetchDesadvs,
      showToastUI,
    ],
  );

  const addItemsToSharedList = useCallback(async () => {
    setIsAddingItems(true);
    setInboundUIState({ isAddToSharedListModalVisible: false });
    try {
      if (!sharedListId) return;
      const products = getInboundUnitsStockUpdates({
        inboundUnitsSortedByDate,
        inboundUnitsStockUpdates,
        inboundUnitsDisplayStates,
      });
      await addItemsToPreDroppingListMutation({
        variables: {
          input: {
            listId: sharedListId,
            products,
          },
        },
      });
      sendInboundStateUpdate({
        state: CollaborativeInboundUpdateState.ListPreparationFinished,
        droppingListId: sharedListId,
        sscc: deliverySSCC,
        inboundingType: deliveryType,
      });
    } catch (error) {
      showToastUI({
        status: "error",
        id: ADD_ITEMS_SHARED_LIST_ERROR_TOAST_ID,
        title: "add_items_shared_list_error_toast",
      });
      const errorMessage = error instanceof Error ? error.message : "Unknown error";
      datadogRum.addError("Add Items Shared List Error", { errorMessage });
    } finally {
      setIsAddingItems(false);
    }
  }, [
    addItemsToPreDroppingListMutation,
    deliverySSCC,
    deliveryType,
    inboundUnitsDisplayStates,
    inboundUnitsSortedByDate,
    inboundUnitsStockUpdates,
    sendInboundStateUpdate,
    setInboundUIState,
    sharedListId,
    showToastUI,
  ]);

  const handleDespatchAdviceModalContinue = useCallback(async () => {
    setInboundUIState({ isDespatchAdviceModalVisible: false });
    await createSharedList(deliveryType);
  }, [createSharedList, deliveryType, setInboundUIState]);

  const handleDespatchAdviceModalRetry = useCallback(() => {
    setInboundUIState({ isDespatchAdviceModalVisible: false });
    setDeliverySSCC(null);
  }, [setDeliverySSCC, setInboundUIState]);

  const handleAddItemsModalSave = useCallback(async () => {
    sendSegmentTrackEvent("inAppMessageShown", {
      screen_name: PageName.INBOUND_PAGE,
      component_content: sharedListId!,
      component_name: "save_list_confirmation",
    });
    setInboundUIState({ isAddToSharedListModalVisible: false });
    await addItemsToSharedList();
  }, [addItemsToSharedList, sendSegmentTrackEvent, setInboundUIState, sharedListId]);

  const handleAddItemsModalCancel = useCallback(() => {
    setInboundUIState({ isAddToSharedListModalVisible: false });
  }, [setInboundUIState]);

  return {
    createSharedList,
    addItemsToSharedList,
    isCreatingList,
    isAddingItems,
    handleDespatchAdviceModalContinue,
    handleDespatchAdviceModalRetry,
    handleAddItemsModalSave,
    handleAddItemsModalCancel,
  };
};
