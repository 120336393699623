import React, { useMemo } from "react";

import { useAuth0 } from "@auth0/auth0-react";
import { createBrowserRouter, Navigate, RouteObject, RouterProvider } from "react-router-dom";

import { TaskType } from "__graphql__/types";
import { PageName } from "analytics/events";
import { config } from "config";
import { routes as routesConfig } from "config/routes";
import { AppLayout } from "core/components/AppLayout";
import { NotUsingPWAPage } from "core/pages/NotUsingPWA";
import { AppLayoutProvider, RouteLayoutProps, Flag } from "core/providers/AppLayoutProvider";
import { useAppPWAStateStore } from "core/stores/useAppPWAStateStore";
import { useEmployeeStore } from "core/stores/useEmployeeStore";
import { OAOccupation } from "core/stores/useOAOccupationStore";
import { EppoFeatureFlags } from "core/types/flags";
import { ActivitiesIndexPage } from "flows/Activities/pages/ActivitiesIndex";
import { DeliveryCheckIn } from "flows/Activities/pages/DeliveryCheckIn/DeliveryCheckIn";
import { FeatureFlagConfig } from "flows/Activities/pages/FeatureFlagConfig";
import { TimedActivitiesDetails } from "flows/Activities/pages/TimedActivitiesDetails/TimedActivitiesDetails";
import { TimedActivitiesListPage } from "flows/Activities/pages/TimedActivitiesList";
import { AuthPage } from "flows/Auth/pages/Auth";
import { CollaborativeInbound } from "flows/Inbound/pages/CollaborativeInbound/CollaborativeInbound";
import { InboundClaimedList } from "flows/Inbound/pages/InboundClaimedList/InboundClaimedList";
import { InboundDroppingList } from "flows/Inbound/pages/InboundDroppingList/InboundDroppingList";
import { InboundDroppingListPage } from "flows/Inbound/pages/InboundDroppingListPage/InboundDroppingListPage";
import { InboundListVerificationPage } from "flows/Inbound/pages/InboundListVerification/InboundListVerification";
import { InboundManualSearch } from "flows/Inbound/pages/InboundManualSearch/InboundManualSearch";
import { InboundNoEANProducts } from "flows/Inbound/pages/InboundNoEANProducts/InboundNoEANProducts";
import { InboundOutboundPage as InboundOutbound } from "flows/Inbound/pages/InboundOutbound/InboundOutbound";
import { InboundOutboundPage } from "flows/Inbound/pages/InboundOutboundPage/InboundOutboundPage";
import { InboundPreDroppingList } from "flows/Inbound/pages/InboundPreDroppingList/InboundPreDroppingList";
import { InboundPreviewProductsPage } from "flows/Inbound/pages/InboundPreviewProductsPage/InboundPreviewProductsPage";
import { InboundScanRolliPage } from "flows/Inbound/pages/InboundScanRolli/InboundScanRolli";
import { InboundSelectDeliveryPage } from "flows/Inbound/pages/InboundSelectDelivery";
import { InboundSharedList } from "flows/Inbound/pages/InboundSharedList/InboundSharedList";
import { InboundSharedListVerification } from "flows/Inbound/pages/InboundSharedListVerification/InboundSharedListVerification";
import { ManualProductsSearchPage } from "flows/Inbound/pages/ManualProductsSearchPage/ManualProductsSearchPage";
import { PreDroppingListPage } from "flows/Inbound/pages/PreDroppingListPage/PreDroppingListPage";
import { ProductsSearchNoEANProductsListPage } from "flows/Inbound/pages/ProductsSearchNoEANProductsListPage/ProductsSearchNoEANProductsListPage";
import { ScanRolliPage } from "flows/Inbound/pages/ScanRolliPage/ScanRolliPage";
import { SelectDeliveryPage } from "flows/Inbound/pages/SelectDeliveryPage/SelectDeliveryPage";
import { XStateInboundProvider } from "flows/Inbound/providers/XStateInboundProvider";
import { XStateProductSearchProvider } from "flows/Inbound/providers/XStateProductSearchProvider";
import { BbdCheckDeclareClosestBdd } from "flows/Inventory/flows/BbdChecks/pages/BbdCheckDeclareClosestBdd/BbdCheckDeclareClosestBdd";
import { BbdCheckDeclareExpired } from "flows/Inventory/flows/BbdChecks/pages/BbdCheckDeclareExpired/BbdCheckDeclareExpired";
import { BbdChecksList } from "flows/Inventory/flows/BbdChecks/pages/BbdChecksList";
import { XStateBbdCheckProvider } from "flows/Inventory/flows/BbdChecks/providers/XStateBbdCheckProvider";
import { DeclareBBDCorrection } from "flows/Inventory/flows/BbdCorrections/pages/DeclareBBDCorrection/DeclareBBDCorrection";
import { BbdCorrectionIdlePage } from "flows/Inventory/flows/BbdCorrections/pages/Idle";
import { SelectBbdProductPage } from "flows/Inventory/flows/BbdCorrections/pages/SelectProduct";
import { XStateBbdCorrectionProvider } from "flows/Inventory/flows/BbdCorrections/providers/XStateBbdCorrectionProvider";
import { EoyCheck } from "flows/Inventory/flows/EoyChecks/pages/EoyCheck";
import { EoyChecksList } from "flows/Inventory/flows/EoyChecks/pages/EoyChecksList";
import { RecountChecksList } from "flows/Inventory/flows/EoyChecks/pages/RecountChecksList";
import { FreshChecksList } from "flows/Inventory/flows/FreshChecks/pages/FreshChecksList";
import { FreshCheckTask } from "flows/Inventory/flows/FreshChecks/pages/FreshCheckTask";
import { XStateFreshCheckProvider } from "flows/Inventory/flows/FreshChecks/providers/XStateFreshCheckProvider";
import { RestockingDropOff } from "flows/Inventory/flows/RestockingList/pages/RestockingDropOff/RestockingDropOff";
import { RestockingListPage } from "flows/Inventory/flows/RestockingList/pages/RestockingListPage/RestockingListPage";
import { RestockingListPreparation } from "flows/Inventory/flows/RestockingList/pages/RestockingListPreparation/RestockingListPreparation";
import { RestockingMoveItems } from "flows/Inventory/flows/RestockingList/pages/RestockingMoveItems/RestockingMoveItems";
import { RestockingProductSearch } from "flows/Inventory/flows/RestockingList/pages/RestockingProductSearch/RestockingProductSearch";
import { RestockingPublicList } from "flows/Inventory/flows/RestockingList/pages/RestockingPublicList/RestockingPublicList";
import { StockCheckQuantityPage } from "flows/Inventory/flows/StockChecks/pages/StockCheckQuantity";
import { StockChecksList } from "flows/Inventory/flows/StockChecks/pages/StockChecksList";
import { StockCheckUpdatePage } from "flows/Inventory/flows/StockChecks/pages/StockCheckUpdate";
import { XStateStockCheckProvider } from "flows/Inventory/flows/StockChecks/providers/XStateStockCheckProvider";
import { DeclareStockCorrectionPage } from "flows/Inventory/flows/StockCorrections/pages/DeclareStockCorrection";
import { IdlePage } from "flows/Inventory/flows/StockCorrections/pages/Idle";
import { SelectProductPage } from "flows/Inventory/flows/StockCorrections/pages/SelectProduct";
import { XStateStockCorrectionProvider } from "flows/Inventory/flows/StockCorrections/providers/XstateStockCorrectionMachineProvider";
import { InventoryPage } from "flows/Inventory/shared/pages/Inventory";
import { AssignShelvesPage } from "flows/Picking/pages/AssignShelves/AssignShelves";
import { ManualPickingPage } from "flows/Picking/pages/ManualPicking";
import { PickingDonePage } from "flows/Picking/pages/PickingDone/PickingDone";
import { PickingIncomplete } from "flows/Picking/pages/PickingIncomplete/PickingIncomplete";
import { PickingIndexPage } from "flows/Picking/pages/PickingIndex/PickingIndex";
import { PickingListPage } from "flows/Picking/pages/PickingList/PickingList";
import { ScanContainersPage } from "flows/Picking/pages/ScanContainers/ScanContainers";
import { XStatePickingProvider } from "flows/Picking/providers/XstatePickingMachineProvider";
import { useEppoFeatureFlagProvider } from "shared/hooks/useEppoFeatureFlag";

const EOY_SHOW_CHECKS_FLAG = {
  featureFlag: EppoFeatureFlags.EOY_SHOW_CHECKS,
  inaccessible: false,
  redirectPath: routesConfig.inventory.root,
};

const EOY_CHECKS_FLAG = {
  featureFlag: EppoFeatureFlags.EOY_CHECKS,
  inaccessible: true,
  redirectPath: routesConfig.inventory.root,
};

const EOY_HIDE_CORRECTIONS_FLAG = {
  featureFlag: EppoFeatureFlags.EOY_HIDE_CORRECTIONS,
  inaccessible: true,
  redirectPath: routesConfig.inventory.root,
};

export type CustomRouteObject = {
  caseSensitive?: boolean;
  children?: CustomRouteObject[];
  element?: React.ReactNode;
  path?: string;
  id?: string;
  flag?: Flag;
} & RouteLayoutProps;

const generateRoutes = (routes: CustomRouteObject[]): RouteObject[] =>
  routes.map(
    ({
      element,
      path,
      id,
      withTabBar,
      highlightedTab,
      canGoBack,
      withWaitingOrdersBannerOfType,
      withMenu,
      withOfflineBanner,
      withAppHeader,
      withScrollableAppHeader,
      withOngoingActivityBanner,
      withTabList,
      canShowUpdateModal,
      pageName,
      oaOccupation,
      flag,
      children,
      ...rest
    }) => ({
      ...rest,
      path,
      element: element ? (
        <AppLayoutProvider
          id={path || id || ""}
          canGoBack={canGoBack}
          withTabBar={withTabBar}
          highlightedTab={highlightedTab}
          withMenu={withMenu}
          withWaitingOrdersBannerOfType={withWaitingOrdersBannerOfType}
          withOfflineBanner={withOfflineBanner}
          withAppHeader={withAppHeader}
          withScrollableAppHeader={withScrollableAppHeader}
          withOngoingActivityBanner={withOngoingActivityBanner}
          withTabList={withTabList}
          canShowUpdateModal={canShowUpdateModal}
          pageName={pageName}
          oaOccupation={oaOccupation}
          flag={flag}
        >
          {element}
        </AppLayoutProvider>
      ) : undefined,
      children: children ? generateRoutes(children) : undefined,
    }),
  );

const featureFlagConfigRoute: CustomRouteObject = {
  path: routesConfig.activities.featureFlagConfig,
  canGoBack: true,
  element: <FeatureFlagConfig />,
  withAppHeader: false,
};

const isInBrowserRoutes: RouteObject[] = generateRoutes([
  {
    path: "*",
    canGoBack: false,
    withWaitingOrdersBannerOfType: "none",
    withMenu: false,
    withAppHeader: false,
    element: <NotUsingPWAPage />,
  },
]);

const publicRoutes: RouteObject[] = generateRoutes([
  featureFlagConfigRoute,
  {
    path: "*",
    canGoBack: false,
    withWaitingOrdersBannerOfType: "disabled-unidentified",
    element: <AuthPage />,
  },
]);

const protectedInboundRoutes: CustomRouteObject = {
  path: routesConfig.inbound.root,
  canGoBack: false,
  element: <XStateInboundProvider />,
  flag: EOY_CHECKS_FLAG,
  withTabBar: true,
  highlightedTab: "inbound",
  children: [
    {
      path: routesConfig.inbound.selectDelivery,
      canGoBack: false,
      element: <InboundSelectDeliveryPage />,
      withOngoingActivityBanner: true,
      withTabBar: true,
      highlightedTab: "inbound",
      pageName: PageName.INBOUND_SELECT_DELIVERY,
      flag: EOY_CHECKS_FLAG,
    },
    {
      path: routesConfig.inbound.scanRolli,
      canGoBack: false,
      element: <InboundScanRolliPage />,
      withOngoingActivityBanner: true,
      withTabBar: true,
      highlightedTab: "inbound",
      pageName: PageName.INBOUND_SELECT_DELIVERY,
      oaOccupation: OAOccupation.Inbounding,
      flag: EOY_CHECKS_FLAG,
    },
    {
      id: routesConfig.inbound.preDropping,
      canGoBack: false,
      element: <XStateProductSearchProvider />,
      withTabBar: true,
      highlightedTab: "inbound",
      withOngoingActivityBanner: true,
      oaOccupation: OAOccupation.Inbounding,
      flag: EOY_CHECKS_FLAG,
      children: [
        {
          path: routesConfig.inbound.preDropping,
          element: <InboundPreDroppingList />,
          canGoBack: false,
          withTabBar: true,
          highlightedTab: "inbound",
          withOngoingActivityBanner: true,
          pageName: PageName.INBOUND_PAGE,
          oaOccupation: OAOccupation.Inbounding,
          flag: EOY_CHECKS_FLAG,
        },
        {
          path: routesConfig.inbound.manualSearch,
          withAppHeader: false,
          withTabBar: false,
          oaOccupation: OAOccupation.Inbounding,
          element: <InboundManualSearch />,
          flag: EOY_CHECKS_FLAG,
        },
        {
          path: routesConfig.inbound.noEANProducts,
          withAppHeader: false,
          withTabBar: false,
          oaOccupation: OAOccupation.Inbounding,
          element: <InboundNoEANProducts />,
          flag: EOY_CHECKS_FLAG,
        },
      ],
    },
    {
      path: routesConfig.inbound.outbound,
      withAppHeader: false,
      oaOccupation: OAOccupation.Inbounding,
      element: <InboundOutbound />,
      flag: EOY_CHECKS_FLAG,
    },
    {
      path: routesConfig.inbound.listVerification,
      withAppHeader: false,
      withTabBar: false,
      oaOccupation: OAOccupation.Inbounding,
      element: <InboundListVerificationPage />,
      flag: EOY_CHECKS_FLAG,
    },
    {
      path: routesConfig.inbound.dropping,
      withAppHeader: false,
      oaOccupation: OAOccupation.Inbounding,
      element: <InboundDroppingList />,
      pageName: PageName.INBOUND_DROPPING_PAGE,
      flag: EOY_CHECKS_FLAG,
    },
  ],
};
const protectedCollaborativeInboundRoutes: CustomRouteObject = {
  path: routesConfig.inbound.root,
  element: <CollaborativeInbound />,
  canGoBack: true,
  withTabBar: true,
  withAppHeader: false,
  highlightedTab: "inbound",
  withOngoingActivityBanner: true,
  withTabList: true,
  pageName: PageName.INBOUND_PAGE_SHARED_LIST,
  flag: EOY_CHECKS_FLAG,
  children: [
    {
      path: routesConfig.inbound.root,
      element: <InboundSharedList />,
      canGoBack: true,
      withTabBar: true,
      highlightedTab: "inbound",
      withAppHeader: false,
      withOngoingActivityBanner: true,
      withTabList: true,
      pageName: PageName.INBOUND_PAGE_SHARED_LIST,
    },
    {
      path: routesConfig.inbound.claimedList,
      element: <InboundClaimedList />,
      canGoBack: true,
      withTabBar: true,
      withAppHeader: false,
      highlightedTab: "inbound",
      withOngoingActivityBanner: true,
      withTabList: true,
      pageName: PageName.INBOUND_PAGE_CLAIMED_LIST,
    },
    {
      path: routesConfig.inbound.selectDelivery,
      element: <SelectDeliveryPage />,
      canGoBack: true,
      withTabBar: true,
      withAppHeader: false,
      highlightedTab: "inbound",
      withOngoingActivityBanner: true,
      pageName: PageName.INBOUND_SELECT_DELIVERY,
      flag: EOY_CHECKS_FLAG,
    },
    {
      path: routesConfig.inbound.scanRolli,
      canGoBack: true,
      withTabBar: false,
      withAppHeader: false,
      element: <ScanRolliPage />,
      withOngoingActivityBanner: true,
      highlightedTab: "inbound",
      pageName: PageName.INBOUND_SELECT_DELIVERY,
      oaOccupation: OAOccupation.Inbounding,
      flag: EOY_CHECKS_FLAG,
    },
    {
      path: routesConfig.inbound.preDropping,
      element: <PreDroppingListPage />,
      canGoBack: true,
      withTabBar: true,
      withAppHeader: false,
      highlightedTab: "inbound",
      withOngoingActivityBanner: true,
      pageName: PageName.INBOUND_PAGE,
      oaOccupation: OAOccupation.Inbounding,
      flag: EOY_CHECKS_FLAG,
    },
    {
      path: routesConfig.inbound.previewProducts,
      element: <InboundPreviewProductsPage />,
      canGoBack: true,
      withTabBar: true,
      withAppHeader: false,
      highlightedTab: "inbound",
      withOngoingActivityBanner: true,
      pageName: PageName.INBOUND_PAGE,
      oaOccupation: OAOccupation.Inbounding,
      flag: EOY_CHECKS_FLAG,
    },
    {
      path: routesConfig.inbound.manualSearch,
      withAppHeader: false,
      withTabBar: false,
      oaOccupation: OAOccupation.Inbounding,
      element: <ManualProductsSearchPage />,
      flag: EOY_CHECKS_FLAG,
    },
    {
      path: routesConfig.inbound.noEANProducts,
      withAppHeader: false,
      withTabBar: false,
      oaOccupation: OAOccupation.Inbounding,
      element: <ProductsSearchNoEANProductsListPage />,
      flag: EOY_CHECKS_FLAG,
    },
    {
      path: routesConfig.inbound.outbound,
      withAppHeader: false,
      withTabBar: false,
      oaOccupation: OAOccupation.Inbounding,
      element: <InboundOutboundPage />,
      flag: EOY_CHECKS_FLAG,
    },
    {
      path: routesConfig.inbound.listVerification,
      withAppHeader: false,
      withTabBar: false,
      oaOccupation: OAOccupation.Inbounding,
      element: <InboundSharedListVerification />,
      flag: EOY_CHECKS_FLAG,
    },
    {
      path: routesConfig.inbound.dropping,
      withAppHeader: false,
      oaOccupation: OAOccupation.Inbounding,
      element: <InboundDroppingListPage />,
      pageName: PageName.INBOUND_DROPPING_PAGE,
      flag: EOY_CHECKS_FLAG,
    },
  ],
};

const protectedRoutes: RouteObject[] = generateRoutes([
  featureFlagConfigRoute,
  {
    path: routesConfig.activities.root,
    canGoBack: false,
    element: <ActivitiesIndexPage />,
    withTabBar: true,
    highlightedTab: "activities",
    withOngoingActivityBanner: true,
    canShowUpdateModal: true,
    pageName: PageName.ACTIVITIES_PAGE,
  },
  {
    path: routesConfig.inventory.root,
    canGoBack: false,
    element: <InventoryPage />,
    withTabBar: true,
    highlightedTab: "inventory",
    withOngoingActivityBanner: true,
    pageName: PageName.INVENTORY_PAGE,
  },
  {
    path: routesConfig.picking.root,
    canGoBack: false,
    element: <XStatePickingProvider />,
    withTabBar: true,
    highlightedTab: "picking",
    children: [
      {
        path: routesConfig.picking.incomplete,
        element: <PickingIncomplete />,
        canGoBack: false,
        withOngoingActivityBanner: false,
        withWaitingOrdersBannerOfType: "none",
      },
      {
        path: routesConfig.picking.root,
        canGoBack: false,
        element: <PickingIndexPage />,
        withTabBar: true,
        highlightedTab: "picking",
        withWaitingOrdersBannerOfType: "none",
        withOngoingActivityBanner: true,
        pageName: PageName.NEW_ORDER_FOR_PICKING_PAGE,
      },
      {
        path: routesConfig.picking.start,
        canGoBack: false,
        element: <PickingListPage />,
        withTabBar: false,
        withAppHeader: false,
        withWaitingOrdersBannerOfType: "none",
        withMenu: false,
        pageName: PageName.PICKING_PAGE,
      },
      {
        path: routesConfig.picking.containers,
        element: <ScanContainersPage />,
        withTabBar: false,
        withMenu: false,
        withWaitingOrdersBannerOfType: "none",
      },
      {
        path: routesConfig.picking.shelves,
        element: <AssignShelvesPage />,
        withTabBar: false,
        withMenu: false,
        withWaitingOrdersBannerOfType: "none",
      },
      {
        path: routesConfig.picking.end,
        canGoBack: false,
        element: <PickingDonePage />,
        withWaitingOrdersBannerOfType: "none",
        withMenu: false,
      },
      {
        path: routesConfig.picking.manual,
        canGoBack: true,
        element: <ManualPickingPage />,
        withOngoingActivityBanner: true,
        pageName: PageName.MANUAL_PICKING_PAGE,
      },
    ],
  },
  {
    path: routesConfig.inventory.stockCorrections.root,
    canGoBack: true,
    withAppHeader: false,
    element: <XStateStockCorrectionProvider />,
    flag: EOY_HIDE_CORRECTIONS_FLAG,
    children: [
      {
        path: routesConfig.inventory.stockCorrections.root,
        canGoBack: true,
        element: <IdlePage />,
        withTabBar: false,
        withAppHeader: false,
        withOngoingActivityBanner: true,
        flag: EOY_HIDE_CORRECTIONS_FLAG,
      },
      {
        path: routesConfig.inventory.stockCorrections.declare,
        canGoBack: true,
        element: <DeclareStockCorrectionPage />,
        withTabBar: false,
        withAppHeader: false,
        withWaitingOrdersBannerOfType: "none",
        flag: EOY_HIDE_CORRECTIONS_FLAG,
      },
      {
        path: routesConfig.inventory.stockCorrections.selectProduct,
        canGoBack: true,
        element: <SelectProductPage />,
        withTabBar: false,
        withAppHeader: false,
        flag: EOY_HIDE_CORRECTIONS_FLAG,
      },
    ],
  },
  {
    path: routesConfig.inventory.restockingList.root,
    element: <RestockingListPage />,
    canGoBack: true,
    withTabBar: true,
    withAppHeader: false,
    highlightedTab: "inventory",
    withOngoingActivityBanner: true,
    withTabList: true,
    flag: EOY_CHECKS_FLAG,
    pageName: PageName.INVENTORY_PAGE_PRIVATE_LIST,
    children: [
      {
        path: routesConfig.inventory.restockingList.root,
        element: <RestockingListPreparation />,
        canGoBack: true,
        withTabBar: true,
        highlightedTab: "inventory",
        withAppHeader: false,
        withOngoingActivityBanner: true,
        withTabList: true,
        flag: EOY_CHECKS_FLAG,
        pageName: PageName.INVENTORY_PAGE_PRIVATE_LIST,
      },
      {
        path: routesConfig.inventory.restockingList.publicList,
        element: <RestockingPublicList />,
        canGoBack: true,
        withTabBar: false,
        withAppHeader: false,
        withOngoingActivityBanner: true,
        withTabList: true,
        flag: EOY_CHECKS_FLAG,
        pageName: PageName.INVENTORY_PAGE_PUBLIC_LIST,
      },
      {
        path: routesConfig.inventory.restockingList.moveItems,
        element: <RestockingMoveItems />,
        canGoBack: true,
        withTabBar: false,
        withAppHeader: false,
        withOngoingActivityBanner: true,
        flag: EOY_CHECKS_FLAG,
        withTabList: true,
        pageName: PageName.INVENTORY_PAGE_MOVE_ITEMS,
      },
      {
        path: routesConfig.inventory.restockingList.dropOff,
        element: <RestockingDropOff />,
        canGoBack: true,
        withTabBar: false,
        withAppHeader: false,
        withOngoingActivityBanner: true,
        flag: EOY_CHECKS_FLAG,
      },
      {
        path: routesConfig.inventory.restockingList.shelfSearch,
        element: <RestockingProductSearch mode="shelf" />,
        canGoBack: false,
        withTabBar: false,
        withAppHeader: false,
        withOngoingActivityBanner: false,
        withWaitingOrdersBannerOfType: "none",
        flag: EOY_CHECKS_FLAG,
      },
      {
        path: routesConfig.inventory.restockingList.textSearch,
        element: <RestockingProductSearch mode="text" />,
        canGoBack: false,
        withTabBar: false,
        withAppHeader: false,
        withOngoingActivityBanner: false,
        withWaitingOrdersBannerOfType: "none",
        flag: EOY_CHECKS_FLAG,
      },
    ],
  },
  {
    path: routesConfig.inventory.bbdCorrections.root,
    canGoBack: true,
    withAppHeader: false,
    element: <XStateBbdCorrectionProvider />,
    flag: EOY_CHECKS_FLAG,
    children: [
      {
        path: routesConfig.inventory.bbdCorrections.root,
        canGoBack: true,
        element: <BbdCorrectionIdlePage />,
        withTabBar: false,
        withAppHeader: false,
        withOngoingActivityBanner: true,
        flag: EOY_CHECKS_FLAG,
      },
      {
        path: routesConfig.inventory.bbdCorrections.declare,
        canGoBack: true,
        element: <DeclareBBDCorrection />,
        withTabBar: false,
        withAppHeader: false,
        withWaitingOrdersBannerOfType: "none",
        flag: EOY_CHECKS_FLAG,
      },
      {
        path: routesConfig.inventory.bbdCorrections.selectProduct,
        canGoBack: true,
        element: <SelectBbdProductPage />,
        withTabBar: false,
        withAppHeader: false,
        withWaitingOrdersBannerOfType: "default",
        flag: EOY_CHECKS_FLAG,
      },
    ],
  },
  {
    path: routesConfig.inventory.stockChecks.root,
    element: <StockChecksList />,
    canGoBack: true,
    withMenu: false,
    withTabBar: false,
    withAppHeader: false,
    withOngoingActivityBanner: true,
    pageName: PageName.INVENTORY_STOCK_CHECK_LIST_PAGE,
    flag: EOY_HIDE_CORRECTIONS_FLAG,
  },
  {
    path: routesConfig.inventory.stockChecks.detail,
    canGoBack: true,
    element: <XStateStockCheckProvider />,
    flag: EOY_HIDE_CORRECTIONS_FLAG,
    children: [
      {
        path: routesConfig.inventory.stockChecks.quantity,
        canGoBack: true,
        element: <StockCheckQuantityPage />,
        withMenu: false,
        withAppHeader: false,
        withWaitingOrdersBannerOfType: "disabled",
        flag: EOY_HIDE_CORRECTIONS_FLAG,
      },
      {
        path: routesConfig.inventory.stockChecks.update,
        canGoBack: true,
        element: <StockCheckUpdatePage />,
        withMenu: false,
        withAppHeader: false,
        withWaitingOrdersBannerOfType: "disabled",
        flag: EOY_HIDE_CORRECTIONS_FLAG,
      },
    ],
    withMenu: false,
    withAppHeader: false,
  },
  {
    path: routesConfig.inventory.eoyChecks.root,
    pageName: PageName.INVENTORY_EOY_CHECK_LIST_PAGE,
    children: [
      {
        path: routesConfig.inventory.eoyChecks.root,
        canGoBack: true,
        element: <EoyChecksList />,
        withMenu: false,
        withAppHeader: false,
        withOngoingActivityBanner: true,
      },
      {
        path: routesConfig.inventory.eoyChecks.detail,
        canGoBack: true,
        element: (
          <EoyCheck
            type={TaskType.eoy_inventory_check}
            origin={PageName.INVENTORY_EOY_CHECK_LIST_PAGE}
          />
        ),
        withMenu: false,
        withAppHeader: false,
        withWaitingOrdersBannerOfType: "disabled",
      },
    ],
    flag: EOY_SHOW_CHECKS_FLAG,
  },
  {
    path: routesConfig.inventory.eoyRecountChecks.root,
    pageName: PageName.INVENTORY_RECOUNT_CHECK_LIST_PAGE,
    children: [
      {
        path: routesConfig.inventory.eoyRecountChecks.root,
        canGoBack: true,
        element: <RecountChecksList />,
        withMenu: false,
        withAppHeader: false,
        withOngoingActivityBanner: true,
      },
      {
        path: routesConfig.inventory.eoyRecountChecks.detail,
        canGoBack: true,
        element: (
          <EoyCheck
            type={TaskType.eoy_inventory_recount_check}
            origin={PageName.INVENTORY_RECOUNT_CHECK_LIST_PAGE}
          />
        ),
        withMenu: false,
        withAppHeader: false,
        withWaitingOrdersBannerOfType: "disabled",
      },
    ],
    flag: {
      ...EOY_CHECKS_FLAG,
      inaccessible: false,
    },
  },
  {
    path: routesConfig.inventory.bbdChecks.root,
    element: <BbdChecksList />,
    canGoBack: true,
    withMenu: false,
    withTabBar: false,
    withAppHeader: false,
    withOngoingActivityBanner: true,
    pageName: PageName.INVENTORY_BBD_CHECK_LIST_PAGE,
    flag: EOY_CHECKS_FLAG,
  },
  {
    path: routesConfig.inventory.bbdChecks.detail,
    canGoBack: true,
    element: <XStateBbdCheckProvider />,
    flag: EOY_CHECKS_FLAG,
    children: [
      {
        path: routesConfig.inventory.bbdChecks.declareExpired,
        canGoBack: true,
        element: <BbdCheckDeclareExpired />,
        withMenu: false,
        withAppHeader: false,
        withWaitingOrdersBannerOfType: "disabled",
        pageName: PageName.INVENTORY_BBD_DECLARE_EXPIRED,
        flag: EOY_CHECKS_FLAG,
      },
      {
        path: routesConfig.inventory.bbdChecks.declareClosestBdd,
        canGoBack: true,
        element: <BbdCheckDeclareClosestBdd />,
        withMenu: false,
        withAppHeader: false,
        withWaitingOrdersBannerOfType: "disabled",
        pageName: PageName.INVENTORY_BBD_DECLARE_CLOSEST_BBD,
        flag: EOY_CHECKS_FLAG,
      },
    ],
    withMenu: false,
    withAppHeader: false,
  },
  {
    path: routesConfig.inventory.freshChecks.root,
    element: <FreshChecksList />,
    canGoBack: true,
    withMenu: false,
    withTabBar: false,
    withAppHeader: false,
    withOngoingActivityBanner: true,
    pageName: PageName.INVENTORY_FRESH_CHECK_LIST_PAGE,
    flag: EOY_CHECKS_FLAG,
  },
  {
    path: routesConfig.inventory.freshChecks.detail,
    canGoBack: true,
    element: <XStateFreshCheckProvider />,
    flag: EOY_CHECKS_FLAG,
    children: [
      {
        path: routesConfig.inventory.freshChecks.quantity,
        canGoBack: true,
        element: <FreshCheckTask />,
        withMenu: false,
        withAppHeader: false,
        withWaitingOrdersBannerOfType: "disabled",
        flag: EOY_CHECKS_FLAG,
      },
    ],
    withMenu: false,
    withAppHeader: false,
  },
  {
    path: routesConfig.activities.deliveryCheckIn,
    canGoBack: true,
    element: <DeliveryCheckIn />,
    withAppHeader: false,
    pageName: PageName.DELIVERY_CHECK_IN,
  },
  {
    path: routesConfig.activities.timedActivities.list,
    canGoBack: true,
    element: <TimedActivitiesListPage />,
    withTabBar: true,
    highlightedTab: "activities",
    withOngoingActivityBanner: true,
    pageName: PageName.HUB_ACTIVITIES,
  },
  {
    path: routesConfig.activities.timedActivities.details,
    canGoBack: true,
    element: <TimedActivitiesDetails />,
    withTabBar: true,
    highlightedTab: "activities",
    withAppHeader: false,
  },
  {
    path: "*",
    canGoBack: false,
    element: <Navigate to={routesConfig.activities.root} />,
    withTabBar: true,
    highlightedTab: "activities",
  },
]);

export function AppRouter() {
  const { isAuthenticated, isLoading } = useAuth0();
  const { isLoadingAppPWAState, displayMode } = useAppPWAStateStore((state) => ({
    isLoadingAppPWAState: state.isLoading,
    displayMode: state.displayMode,
  }));
  const isIdentified = useEmployeeStore((state) => state.isIdentified);

  const { isFeatureEnabled: isCollaborativeInboundEnabled } = useEppoFeatureFlagProvider(
    EppoFeatureFlags.COLLABORATIVE_INBOUND,
  );
  const router = useMemo(() => {
    let usedRoutes: RouteObject[];
    if (config.environment.FORCE_PWA && (isLoadingAppPWAState || displayMode === "browser")) {
      usedRoutes = isInBrowserRoutes;
    } else if (isLoading || !isAuthenticated || !isIdentified) {
      usedRoutes = publicRoutes;
    } else {
      const inboundRoutes: RouteObject[] = generateRoutes([
        featureFlagConfigRoute,
        isCollaborativeInboundEnabled
          ? protectedCollaborativeInboundRoutes
          : protectedInboundRoutes,
      ]);

      usedRoutes = [...protectedRoutes, ...inboundRoutes];
    }
    return createBrowserRouter([
      {
        element: <AppLayout />,
        children: usedRoutes,
      },
    ]);
  }, [
    isLoadingAppPWAState,
    displayMode,
    isLoading,
    isAuthenticated,
    isIdentified,
    isCollaborativeInboundEnabled,
  ]);

  return <RouterProvider router={router} />;
}
