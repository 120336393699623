/* eslint-disable react/no-array-index-key */
import { useCallback, useRef } from "react";

import {
  Box,
  Flex,
  Text,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  IconButton,
  Image,
} from "@chakra-ui/react";
import { datadogRum } from "@datadog/browser-rum";
import { FormattedMessage, useIntl } from "react-intl";
import { useNavigate } from "react-router";
import shallow from "zustand/shallow";

import { InboundingListProduct, InboundingListStatus } from "__graphql__/types";
import { routes } from "config/routes";
import { useLayoutStore } from "core/stores/useLayoutStore";
import useSwipeScroll from "shared/hooks/useSwipeScroll";
import { useFetchProductsBySkus } from "shared/stores/useProductSearchService";
import { ThreedotsIcon, InventoryIcon, NewProfileIcon, FilledPencilIcon } from "ui/Icons/Icons";
import { SideButton } from "ui/SideButton/SideButton";
import { SwipeScrollWrapper } from "ui/SwipeScrollWrapper";
import { TitleXS } from "ui/Typography/Typography";
import { getFormattedDate } from "utils/formats";
import { formatImage } from "utils/image";

import { useGetMultipleDesadvsRolliIdLazyQuery } from "../queries/despatchAdvice/despatchAdvice.generated";
import { useInboundStore } from "../stores/inboundStore/useInboundStore";
import { useClaimInboundList } from "../stores/useInboundDropService";

type SharedListComponentProps = {
  listId: string;
  listName: string;
  deliverySSCC: string;
  createdAt: string;
  status: InboundingListStatus;
  products?: InboundingListProduct[] | null;
};

const getFormattedProductImages = (products: InboundingListProduct[] | null) => {
  return products
    ?.map((product?: InboundingListProduct) =>
      product?.imageUrl ? formatImage(product.imageUrl, 38, 38) : null,
    )
    .filter((url: string | null): url is string => url !== null)
    .slice(0, 5);
};

function HeaderSection({
  listName,
  status,
  onClaimSharedList,
}: {
  listName: string;
  status: InboundingListStatus;
  onClaimSharedList(): void;
}) {
  return (
    <Flex
      justifyContent="space-between"
      borderBottomWidth="1px"
      borderBottomStyle="solid"
      borderBottomColor="grey.200"
      alignItems="center"
      pb="s100"
    >
      <Flex alignItems="center">
        <InventoryIcon boxSize="24px" />
        <TitleXS fontWeight="medium" ml={2}>
          {listName}
        </TitleXS>
      </Flex>
      <Menu>
        <MenuButton
          as={IconButton}
          icon={<ThreedotsIcon boxSize="24px" />}
          variant="unstyled"
          aria-label="Options"
        />
        <MenuList minWidth="140px" borderRadius="sm" width="auto" mt="-45px" boxShadow="lg">
          {status === InboundingListStatus.inbounding_list_status_unclaimed && (
            <MenuItem
              icon={<NewProfileIcon boxSize={4} />}
              sx={{
                _hover: {
                  bg: "transparent",
                },
              }}
              onClick={onClaimSharedList}
              paddingY="s150"
            >
              <FormattedMessage id="flows.inbound.components.shared-list-component.menu-claim" />
            </MenuItem>
          )}
          <MenuItem
            icon={<FilledPencilIcon boxSize={4} />}
            sx={{
              _hover: {
                bg: "transparent",
              },
            }}
            paddingY="s150"
          >
            <FormattedMessage id="flows.inbound.components.shared-list-component.menu-edit" />
          </MenuItem>
        </MenuList>
      </Menu>
    </Flex>
  );
}

function ProductSection({ products }: { products?: InboundingListProduct[] | null }) {
  if (!products) return null;

  const remainingProductsCount = products.length > 5 ? products.length - 5 : 0;
  const productImages = getFormattedProductImages(products);

  return (
    <Flex justifyContent="space-between" alignItems="center">
      <Flex align="center" gap={1.5}>
        {productImages &&
          productImages.map((imageUrl, index) => (
            <Box key={index} borderRadius="sm" overflow="hidden" boxShadow="sm">
              <Image src={imageUrl} alt={imageUrl} boxSize="38px" />
            </Box>
          ))}
      </Flex>
      {remainingProductsCount > 0 && (
        <Box
          borderRadius="sm"
          overflow="hidden"
          boxShadow="sm"
          display="flex"
          alignItems="center"
          justifyContent="center"
          backgroundColor="grey.100"
          boxSize="38px"
        >
          <Text color="grey.500">{remainingProductsCount}</Text>
        </Box>
      )}
    </Flex>
  );
}

function FooterSection({ totalProducts, createdAt }: { totalProducts: number; createdAt: string }) {
  const intl = useIntl();
  const date = getFormattedDate(createdAt, intl);
  return (
    <Flex justifyContent="space-between" alignItems="center">
      <Box w="50%">
        <Text fontSize="sm" color="grey.500">
          <FormattedMessage id="flows.inbound.components.shared-list-component.date" />
        </Text>
        <Text fontSize="sm" fontWeight="bold">
          {date}
        </Text>
      </Box>

      <Box w="50%">
        <Text fontSize="sm" color="grey.500">
          <FormattedMessage id="flows.inbound.components.shared-list-component.products" />
        </Text>
        <Text fontSize="sm" fontWeight="bold">
          {intl.formatMessage(
            { id: "flows.inbound.components.shared-list-component.items" },
            { amount: totalProducts },
          )}
        </Text>
      </Box>
    </Flex>
  );
}

export function SharedListComponent({
  listId,
  listName,
  deliverySSCC,
  createdAt,
  status,
  products,
}: SharedListComponentProps) {
  const intl = useIntl();
  const navigate = useNavigate();

  const wrapperRef = useRef<HTMLDivElement | null>(null);
  const scrollContainerRefFromStore = useLayoutStore((state) => state.scrollContainerRef);
  const { handleSwipeScroll, isScrolledLeft } = useSwipeScroll(
    scrollContainerRefFromStore,
    wrapperRef,
  );

  const { claimInboundList } = useClaimInboundList();
  const { setDeliverySSCC, setSharedListId, setSharedListName, updateDespatchAdviceItems } =
    useInboundStore(
      (state) => ({
        setDeliverySSCC: state.setDeliverySSCC,
        setSharedListId: state.setSharedListId,
        setSharedListName: state.setSharedListName,
        updateDespatchAdviceItems: state.updateDespatchAdviceItems,
      }),
      shallow,
    );

  const { fetchInboundPreviewProducts: fetchProductsBySkus } = useFetchProductsBySkus();

  const [fetchDesadvs] = useGetMultipleDesadvsRolliIdLazyQuery({
    onCompleted: (data) =>
      updateDespatchAdviceItems(data?.getMultipleDesadvsRolliID?.despatchAdvices ?? null),
    onError: (error) => {
      const errorMessage = error instanceof Error ? error.message : "An unknown error occurred.";
      datadogRum.addError("Error fetching despatch advices for delivery SSCC:", { errorMessage });
    },
  });

  const handleListClick = async () => {
    setSharedListId(listId);
    setSharedListName(listName);
    if (deliverySSCC) {
      setDeliverySSCC(deliverySSCC);
      await fetchDesadvs({ variables: { input: { rolliID: deliverySSCC } } });
    }

    if (status === InboundingListStatus.inbounding_list_status_draft) {
      navigate(routes.inbound.preDropping);
    } else {
      await fetchProductsBySkus(products);
    }
  };

  const onClickClaimSharedList = useCallback(() => {
    setDeliverySSCC(deliverySSCC);
    claimInboundList(listId);
  }, [claimInboundList, deliverySSCC, listId, setDeliverySSCC]);

  const mainComponent = (
    <Flex
      direction="column"
      bg="white"
      borderRadius={isScrolledLeft ? "none" : "md"}
      p={4}
      gap={4}
      cursor="pointer"
    >
      <HeaderSection
        listName={listName}
        onClaimSharedList={onClickClaimSharedList}
        status={status}
      />
      <Flex direction="column" gap={4} onClick={handleListClick}>
        <ProductSection products={products} />
        <FooterSection totalProducts={products?.length ?? 0} createdAt={createdAt} />
      </Flex>
    </Flex>
  );

  const scrolledComponent = (
    <Box flex="0 0 137px">
      <SideButton
        color="marine.500"
        bgColor="marine.200"
        icon={<NewProfileIcon boxSize={6} />}
        label={intl.formatMessage({
          id: "flows.inbound.components.shared-list-component.button",
          defaultMessage: "Claim list",
        })}
        onClick={onClickClaimSharedList}
        data-testid="remove-product"
        height="100%"
        borderRightRadius="md"
      />
    </Box>
  );

  return (
    <SwipeScrollWrapper
      mainComponent={mainComponent}
      handleSwipeScroll={handleSwipeScroll}
      scrolledComponent={scrolledComponent}
      showSwipeButtons={status === InboundingListStatus.inbounding_list_status_unclaimed}
      ref={wrapperRef}
    />
  );
}
