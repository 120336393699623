import { useCallback, useEffect, useMemo, useState } from "react";

import { Box, Flex, IconButton } from "@chakra-ui/react";
import { FormattedMessage, useIntl } from "react-intl";
import { useNavigate } from "react-router";

import { routes } from "config/routes";
import { EppoFeatureFlags } from "core/types/flags";
import { InboundDroppingListItem } from "flows/Inbound/components/InboundDroppingListItem";
import { InboundEndDroppingButton } from "flows/Inbound/components/InboundEndDroppingButton";
import { UnclaimListConfirmationModal } from "flows/Inbound/components/UnclaimListConfirmationModal";
import { useInboundStore } from "flows/Inbound/stores/inboundStore/useInboundStore";
import {
  useFetchClaimedProducts,
  useUnClaimInboundList,
} from "flows/Inbound/stores/useInboundDropService";
import { Page } from "shared/components/Page";
import { SpinnerModal } from "shared/components/SpinnerModal";
import { useEppoFeatureFlagProvider } from "shared/hooks/useEppoFeatureFlag";
import { useScrollToActiveItem } from "shared/hooks/useScrollToActiveItem";
import { AscDscIcon } from "ui/AscDscIcon/AscDscIcon";
import { Banner } from "ui/Banner/Banner";
import { TapIcon } from "ui/Icons/Icons";
import { NavigationHeader } from "ui/NavigationHeader/NavigationHeader";
import { isNullOrUndefined } from "utils/tsHelpers";
import { transformChunks } from "utils/ui";

export function InboundDroppingListPage() {
  const intl = useIntl();
  const navigate = useNavigate();
  const [isBannerVisible, setBannerVisible] = useState(true);

  const { isFetchingClaimedProducts } = useFetchClaimedProducts();
  const { unClaimInboundList, isUnClaimingList } = useUnClaimInboundList();
  const {
    droppingListId,
    droppingListName,
    droppingListShelfSortOrder,
    claimedProductsSortedByShelf,
    claimedProductsStockUpdates,
    inboundUIState,
    toggleDroppingListShelfSortOrder,
    setInboundUIState,
  } = useInboundStore((state) => ({
    droppingListId: state.droppingListId,
    droppingListName: state.droppingListName || "",
    droppingListShelfSortOrder: state.droppingListShelfSortOrder,
    claimedProductsSortedByShelf: state.claimedProductsSortedByShelf,
    claimedProductsStockUpdates: state.claimedProductsStockUpdates,
    inboundUIState: state.inboundUIState,
    toggleDroppingListShelfSortOrder: state.toggleDroppingListShelfSortOrder,
    setInboundUIState: state.setInboundUIState,
  }));

  useEffect(() => {
    if (!droppingListId) navigate(routes.inbound.root);
  }, [droppingListId, navigate]);

  const droppingListInboundUnitsSkusSortedByShelf = useMemo(
    () => claimedProductsSortedByShelf.map((p) => p.sku),
    [claimedProductsSortedByShelf],
  );

  const activeItemId =
    useMemo(
      () =>
        droppingListInboundUnitsSkusSortedByShelf.find((sku) => {
          const stockUpdateState = claimedProductsStockUpdates[sku];
          return !!stockUpdateState && !stockUpdateState.stockUpdated;
        }),
      [droppingListInboundUnitsSkusSortedByShelf, claimedProductsStockUpdates],
    ) || null;
  const isDroppingComplete = isNullOrUndefined(activeItemId);

  const { itemsRef, scrollContainerRef } = useScrollToActiveItem({
    activeItemId,
    listItemIds: droppingListInboundUnitsSkusSortedByShelf,
  });

  const onClickGoBack = useCallback(() => {
    setInboundUIState({ isUnclaimListConfirmationModalVisible: true });
  }, [setInboundUIState]);

  const handleTapOnCardBanner = () => setBannerVisible(false);

  const translatedLabels = useMemo(
    () => ({
      singleUnitsLabel: intl.formatMessage({
        id: "components.inbound.inbound-units-list.single-units-label",
      }),
      handlingUnitsLabel: intl.formatMessage({
        id: "components.inbound.inbound-units-list.handling-units-label",
      }),
      inboundedLabel: intl.formatMessage({
        id: "components.inbound.inbound-units-list.inbounded-label",
      }),
      totalUnitsLabel: intl.formatMessage({
        id: "components.inbound.inbound-units-list.total-handling-units-label",
      }),
      bioLabel: intl.formatMessage({
        id: "components.inbound.inbound-units-list.bio-label",
      }),
      nonBioLabel: intl.formatMessage({
        id: "components.inbound.inbound-units-list.non-bio-label",
      }),
      outboundedLabel: intl.formatMessage({
        id: "components.inbound.inbound-units-list.outbounded-label",
      }),
      outboundLabel: intl.formatMessage({
        id: "components.inbound.inbound-units-list.outbound-label",
      }),
      droppingLabel: intl.formatMessage({
        id: "components.inbound.inbound-units-list.dropping-label",
      }),
      inStockLabel: intl.formatMessage({
        id: "components.inbound.inbound-units-list.in-stock-label",
      }),
    }),
    [intl],
  );

  const { isFeatureEnabled: isProductFacingEnabled } = useEppoFeatureFlagProvider(
    EppoFeatureFlags.PRODUCT_FACING,
  );

  const showTapOnCardAlert = isProductFacingEnabled && isBannerVisible && !isDroppingComplete;

  return (
    <Page isFull isBgGrey height="100%">
      <NavigationHeader
        onClickGoBack={onClickGoBack}
        title={droppingListName}
        rightElement={
          <IconButton
            size="xs"
            variant="ghost"
            aria-label="Change sort order"
            icon={<AscDscIcon height="24px" width="24px" sortState={droppingListShelfSortOrder} />}
            onClick={toggleDroppingListShelfSortOrder}
          />
        }
      />
      {showTapOnCardAlert && (
        <Banner
          colorVariant="purple"
          icon={<TapIcon />}
          subTitle={
            <FormattedMessage
              id="components.inbound.inbound-units-list.banner"
              values={transformChunks({ b: { as: "strong", color: "black" } })}
            />
          }
          onClose={handleTapOnCardBanner}
        />
      )}
      <Flex
        direction="column"
        pos="relative"
        ref={scrollContainerRef}
        gap="s150"
        flex={1}
        overflowY="scroll"
        w="100%"
        pt="s200"
      >
        {droppingListInboundUnitsSkusSortedByShelf.map((sku, index) => (
          <InboundDroppingListItem
            key={sku}
            sku={sku}
            elementRef={itemsRef.current[index]}
            translatedLabels={translatedLabels}
            handleTapOnCardBanner={handleTapOnCardBanner}
          />
        ))}
        {/* Below box helps us having the EndDroppingProcessButton not overflowing bottom content */}
        <Box mb="60px" />
        {isDroppingComplete && <InboundEndDroppingButton />}
      </Flex>
      <UnclaimListConfirmationModal
        isOpen={inboundUIState.isUnclaimListConfirmationModalVisible}
        onClickYes={() => {
          if (droppingListId) unClaimInboundList(droppingListId);
        }}
        onClickNo={() => setInboundUIState({ isUnclaimListConfirmationModalVisible: false })}
      />
      <SpinnerModal isOpen={isFetchingClaimedProducts || isUnClaimingList} />
    </Page>
  );
}
