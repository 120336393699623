import { DespatchAdviceItem, InventoryChangeReason, ListProductStatus } from "__graphql__/types";
import { SerializedListVerificationCheck } from "flows/Inbound/models/listVerificationCheck/types";
import { TranslatedProductSearchUnit, UnitType } from "shared/models/productSearchUnit/types";

export type DroppingListSortOrder = "asc" | "desc";

export type InboundUnitsMap = Record<string, TranslatedProductSearchUnit>;

export type ClaimedProductsMap = Record<string, TranslatedClaimedProduct>;

export type InboundUnitStockUpdate = {
  stockUpdatePlan: {
    reason: InventoryChangeReason;
    quantityDelta: number;
  }[];
  stockUpdated: boolean;
};

export type InboundUnitDisplay = {
  unitSizeForDisplay: number;
};

export type InboundUnitsStockUpdates = Record<string, InboundUnitStockUpdate>;
export type InboundUnitsDisplays = Record<string, InboundUnitDisplay>;

export type InboundUIState = {
  isQuantityEditActive: boolean;
  currentLetterRangeId: string;
  isScanningFailedModalVisible: boolean;
  isPartialOutboundAnimationVisible: string | null;
  skuFocusedForQuantityInput: string | null | undefined;
  isHUSizeEditActive: boolean;
  isQuantityMismatchBannerVisible: boolean;
  isDespatchAdviceModalVisible: boolean;
  isDespatchAdviceInfoBannerVisible: boolean;
  isProductAlreadyAddedBannerVisible: boolean;
  isUnverifiedQuantityModalVisible: boolean;
  isAddToSharedListModalVisible: boolean;
  isUnsavedChangesWarningModalVisible: boolean;
  isListValidationActive: boolean;
  isUnclaimListConfirmationModalVisible: boolean;
};

export type DespatchSummaryItem = {
  handlingUnitSize: number;
  totalQuantity: number;
  expectedNumberOfUnits: number;
  unitType: UnitType;
};

export type TranslatedProduct = {
  productName: string | null;
  productSku: string;
  productImageUrl: string | null;
  isProductBio: boolean | null;
  countryOfOriginName: string | null;
  shelfLetter: string | null;
  shelfNumber: string | null;
  totalInboundQuantity: number;
  handlingUnitSize: number;
  unitSizeForDisplay: number;
};

export type ClaimedProductForSorting = {
  name: string | null;
  sku: string;
  shelf: string | null;
  shelfLetter: string | null;
  shelfNumber: string | null;
};

export type TranslatedClaimedProduct = {
  productId: string;
  productName: string | null;
  productSku: string;
  productImageUrl: string | null;
  shelf: string | null;
  shelfLetter: string | null;
  shelfNumber: string | null;
  numberOfShelfFacings: number;
  isShelvedInHandlingUnits: boolean;
  countryOfOriginName: string | null;
  countryOfOriginCode: string | null;
  isProductBio: boolean | null;
  unitType: UnitType;
  handlingUnitSize: number;
  totalInboundQuantity: number;
  unitSizeForDisplay: number;
  stockOnShelf: number;
  status: ListProductStatus;
};

export type InboundStoreContext = {
  deliverySSCC: string | null;
  deliveryType: DeliveryType | null;
  sharedListName: string | null;
  sharedListId: string | null;
  inboundUnitsMap: InboundUnitsMap;
  inboundUnitsSortedByDate: string[];
  inboundUnitsStockUpdates: InboundUnitsStockUpdates;
  inboundUnitsDisplayStates: InboundUnitsDisplays;
  outboundSku?: string;
  shelfStockLevels: Record<string, number>;
  verificationChecks: SerializedListVerificationCheck[];
  despatchAdviceItems: Record<string, DespatchAdviceItem[]>;
  skusWithUnconfirmedQuantities: string[];
  inboundPreviewProducts: TranslatedProduct[];
  droppingListShelfSortOrder: DroppingListSortOrder;
  droppingListId: string | null;
  droppingListName: string | null;
  claimedProductsMap: ClaimedProductsMap;
  claimedProductsSortedByShelf: ClaimedProductForSorting[];
  claimedProductsStockUpdates: InboundUnitsStockUpdates;
  inboundUIState: InboundUIState;
};

export type ClaimedProductsState = Pick<
  InboundStoreContext,
  | "droppingListId"
  | "droppingListName"
  | "claimedProductsMap"
  | "claimedProductsSortedByShelf"
  | "claimedProductsStockUpdates"
>;

export enum InboundUnitAddOrigin {
  Scan = "scan",
  TextSearch = "textSearch",
  NoEANSearch = "noEANSearch",
}

export enum DeliveryType {
  REWE = "REWE",
  Lekkerland = "Lekkerland",
  OtherDelivery = "other-delivery",
  NotDelivery = "not-delivery",
}

export type DeliveryStore = {
  selectedOption: DeliveryType | null;
  deliverySSCC: string | null;
  sharedListName: string | null;
  sharedListId: string | null;
};
