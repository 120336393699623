import { useCallback, memo } from "react";

import { EppoFeatureFlags } from "core/types/flags";
import { useEppoFeatureFlagProvider } from "shared/hooks/useEppoFeatureFlag";
import {
  InboundDroppingListItemCard,
  InboundDroppingListItemCardTranslatedLabelsProps,
} from "ui/InboundDroppingListItemCard/InboundDroppingListItemCard";

import { useInboundStore } from "../stores/inboundStore/useInboundStore";
import { useInboundClaimedProduct } from "../stores/useInboundDropService";

type InboundDroppingListItemProps = {
  sku: string;
  onClickCard?: () => void;
  handleTapOnCardBanner: () => void;
  translatedLabels: InboundDroppingListItemCardTranslatedLabelsProps;
  elementRef: React.RefObject<HTMLDivElement>;
};

function InboundDroppingListItemComponent({
  sku,
  translatedLabels,
  elementRef,
  handleTapOnCardBanner,
}: InboundDroppingListItemProps) {
  const { claimedProductsMap, claimedProductsStockUpdates } = useInboundStore((state) => ({
    claimedProductsMap: state.claimedProductsMap,
    claimedProductsStockUpdates: state.claimedProductsStockUpdates,
  }));
  const {
    unitSizeForDisplay,
    totalInboundQuantity,
    handlingUnitSize,
    productId,
    ...claimedProducts
  } = claimedProductsMap[sku];
  const { stockUpdated } = claimedProductsStockUpdates[sku];
  const { inboundClaimedProduct, isInboundingProduct } = useInboundClaimedProduct();

  const onClickCard = useCallback(() => {
    if (isInboundingProduct || stockUpdated) {
      return;
    }
    handleTapOnCardBanner();
    inboundClaimedProduct(sku, productId);
  }, [
    isInboundingProduct,
    stockUpdated,
    handleTapOnCardBanner,
    inboundClaimedProduct,
    sku,
    productId,
  ]);

  const { isFeatureEnabled: isProductFacingEnabled } = useEppoFeatureFlagProvider(
    EppoFeatureFlags.PRODUCT_FACING,
  );

  return (
    <InboundDroppingListItemCard
      stockUpdatePlanTotal={totalInboundQuantity}
      onClickCard={onClickCard}
      ref={elementRef}
      displayQuantity={unitSizeForDisplay}
      isStockUpdateComplete={stockUpdated}
      displayAsHandlingUnit={handlingUnitSize > 1}
      shouldShowProductFacing={!!isProductFacingEnabled}
      {...translatedLabels}
      {...claimedProducts}
    />
  );
}

export const InboundDroppingListItem = memo(InboundDroppingListItemComponent);

InboundDroppingListItem.displayName = "InboundDroppingListItem";
