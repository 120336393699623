import { useCallback, useEffect, useRef, useMemo } from "react";

import { Flex } from "@chakra-ui/react";
import { useNavigate } from "react-router";
import shallow from "zustand/shallow";

import { routes } from "config/routes";
import { HEIGHT } from "core/components/Header";
import { openOngoingActivityModal } from "core/stores/useLayoutStore";
import { AddItemsToSharedListButton } from "flows/Inbound/components/AddItemsToSharedListButton";
import { DespatchAdviceInfoBanner } from "flows/Inbound/components/DespatchAdviceInfoBanner";
import { HUSizeQuantityMismatchBanner } from "flows/Inbound/components/HUSizeQuantityMismatchBanner";
import { InboundIndexInstructions } from "flows/Inbound/components/InboundIndexInstructions";
import { PreDroppingListPreparation } from "flows/Inbound/components/PreDroppingListPreparation";
import { ProductAlreadyAddedBanner } from "flows/Inbound/components/ProductAlreadyAddedBanner";
import { ProductsSearchComponent } from "flows/Inbound/components/ProductsSearchComponent";
import { ScanningFailedModal } from "flows/Inbound/components/ScanningFailedModal";
import { SharedListCreationConfirmationModal } from "flows/Inbound/components/SharedListCreationConfirmationModal";
import { SharedListUnsavedChangesWarningModal } from "flows/Inbound/components/SharedListUnsavedChangesWarningModal";
import { SharedListVerificationButton } from "flows/Inbound/components/SharedListVerificationButton";
import { UnverifiedQuantityModal } from "flows/Inbound/components/UnverifiedQuantityModal";
import useScrollDetection from "flows/Inbound/hooks/useScrollDetection";
import {
  DEFAULT_LETTER_RANGE_ID,
  useInboundStore,
} from "flows/Inbound/stores/inboundStore/useInboundStore";
import { useSharedListCreator } from "flows/Inbound/stores/useSharedListCreator";
import { Page } from "shared/components/Page";
import { SpinnerModal } from "shared/components/SpinnerModal";
import { useScan } from "shared/hooks/useScan/useScan";
import { useFetchProductsByEan } from "shared/stores/useProductSearchService";
import {
  ProductSearchActionTypes,
  useProductSearchStore,
} from "shared/stores/useProductSearchStore";
import { useTimedActivityStore } from "shared/stores/useTimedActivityStore";
import { NavigationHeader } from "ui/NavigationHeader/NavigationHeader";
import { isNotNullNorUndefined } from "utils/tsHelpers";

export function PreDroppingListPage() {
  const navigate = useNavigate();
  const preDroppingListRef = useRef<HTMLDivElement | null>(null);
  const isPreDroppingListScrolling = useScrollDetection(preDroppingListRef);
  const timedActivityInstance = useTimedActivityStore((state) => state.timedActivityInstance);
  const { isAddingItems, handleAddItemsModalSave, handleAddItemsModalCancel } =
    useSharedListCreator();
  const { onScan, isScannedProductLoading } = useFetchProductsByEan();
  const setSearchState = useProductSearchStore((state) => state.setSearchState);

  const {
    sharedListName,
    inboundUnitsSortedByDate,
    skusWithUnconfirmedQuantities,
    inboundUIState,
    setInboundUIState,
    resetToInitialState,
  } = useInboundStore(
    (state) => ({
      sharedListName: state.sharedListName,
      inboundUnitsSortedByDate: state.inboundUnitsSortedByDate,
      skusWithUnconfirmedQuantities: state.skusWithUnconfirmedQuantities,
      inboundUIState: state.inboundUIState,
      setInboundUIState: state.setInboundUIState,
      resetToInitialState: state.resetToInitialState,
    }),
    shallow,
  );
  const {
    isListValidationActive,
    isQuantityEditActive,
    isScanningFailedModalVisible,
    isDespatchAdviceInfoBannerVisible,
    isProductAlreadyAddedBannerVisible,
    isUnverifiedQuantityModalVisible,
    isUnsavedChangesWarningModalVisible,
    isQuantityMismatchBannerVisible,
  } = inboundUIState;
  const hasUnconfirmedQuantities = skusWithUnconfirmedQuantities.length > 0;
  const preDroppingListIsEmpty = inboundUnitsSortedByDate.length === 0;
  const shouldShowDespatchAdviceInfoBanner =
    hasUnconfirmedQuantities && isDespatchAdviceInfoBannerVisible;

  const { showValidationButton, showAddItemsButton } = useMemo(() => {
    const shouldHideButtons =
      hasUnconfirmedQuantities ||
      inboundUnitsSortedByDate.length === 0 ||
      isQuantityEditActive ||
      isPreDroppingListScrolling;

    if (shouldHideButtons) {
      return { showValidationButton: false, showAddItemsButton: false };
    }
    if (isListValidationActive) {
      return { showValidationButton: true, showAddItemsButton: false };
    }
    return { showValidationButton: false, showAddItemsButton: true };
  }, [
    hasUnconfirmedQuantities,
    inboundUnitsSortedByDate.length,
    isQuantityEditActive,
    isListValidationActive,
    isPreDroppingListScrolling,
  ]);

  useEffect(() => {
    const keyboard = (navigator as any).virtualKeyboard;
    if (!keyboard) {
      return () => {};
    }
    const defaultKeyboardState = keyboard.overlaysContent;
    keyboard.overlaysContent = true;
    return () => {
      keyboard.overlaysContent = defaultKeyboardState;
    };
  }, []);

  useEffect(() => {
    if (!sharedListName) navigate(routes.inbound.root);
  }, [navigate, sharedListName]);

  const activateSearchFlow = useCallback(() => {
    if (hasUnconfirmedQuantities) {
      setInboundUIState({ isUnverifiedQuantityModalVisible: true });
      return;
    }
    if (isNotNullNorUndefined(timedActivityInstance)) {
      openOngoingActivityModal();
      return;
    }
    setInboundUIState({ currentLetterRangeId: DEFAULT_LETTER_RANGE_ID });
    setSearchState(ProductSearchActionTypes.ACTIVATE_SEARCH);
    navigate(routes.inbound.manualSearch);
  }, [
    hasUnconfirmedQuantities,
    navigate,
    setInboundUIState,
    setSearchState,
    timedActivityInstance,
  ]);

  useScan({ onScan });

  if (!sharedListName) return null;

  return (
    <Page isFull isBgGrey h="100%">
      <NavigationHeader
        title={sharedListName}
        onClickGoBack={() => {
          if (!preDroppingListIsEmpty) {
            setInboundUIState({ isUnsavedChangesWarningModalVisible: true });
          } else {
            resetToInitialState();
            navigate(routes.inbound.root);
          }
        }}
      />
      {!preDroppingListIsEmpty && isProductAlreadyAddedBannerVisible && (
        <ProductAlreadyAddedBanner
          onClose={() => {
            setInboundUIState({ isProductAlreadyAddedBannerVisible: false });
          }}
        />
      )}
      {isQuantityMismatchBannerVisible && (
        <HUSizeQuantityMismatchBanner
          onClose={() => setInboundUIState({ isQuantityMismatchBannerVisible: false })}
        />
      )}
      {shouldShowDespatchAdviceInfoBanner && <DespatchAdviceInfoBanner />}
      <ProductsSearchComponent isSearchFlowActive={false} activateTextSearch={activateSearchFlow} />
      {preDroppingListIsEmpty ? (
        <Flex
          flex={1}
          w="100%"
          h="100%"
          maxH={`calc(100% - ${HEIGHT})`}
          align="center"
          justify="center"
          p="s200"
        >
          <InboundIndexInstructions />
        </Flex>
      ) : (
        <Flex
          ref={preDroppingListRef}
          direction="column"
          flex={1}
          w="100%"
          h="100%"
          maxH="calc(100% - 129px)"
          justify="flex-start"
          bg="grey.100"
          overflowX="scroll"
          paddingTop={shouldShowDespatchAdviceInfoBanner ? HEIGHT : undefined}
        >
          <PreDroppingListPreparation />
          {showValidationButton && <SharedListVerificationButton />}
          {showAddItemsButton && <AddItemsToSharedListButton />}
        </Flex>
      )}
      <ScanningFailedModal
        isOpen={isScanningFailedModalVisible}
        onClickConfirm={() => setInboundUIState({ isScanningFailedModalVisible: false })}
      />
      <UnverifiedQuantityModal
        isOpen={isUnverifiedQuantityModalVisible}
        onVerifyQuantity={() => {
          setInboundUIState({ isUnverifiedQuantityModalVisible: false });
        }}
      />
      <SharedListUnsavedChangesWarningModal
        isOpen={isUnsavedChangesWarningModalVisible}
        onClickYes={() => {
          resetToInitialState();
          setInboundUIState({ isUnsavedChangesWarningModalVisible: false });
          navigate(routes.inbound.root);
        }}
        onClickNo={() => setInboundUIState({ isUnsavedChangesWarningModalVisible: false })}
      />
      <SharedListCreationConfirmationModal
        isOpen={inboundUIState.isAddToSharedListModalVisible}
        onClickYes={handleAddItemsModalSave}
        onClickNo={handleAddItemsModalCancel}
      />
      <SpinnerModal isOpen={isAddingItems || isScannedProductLoading} />
    </Page>
  );
}
