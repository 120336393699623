// List of events to be tracked
// Event Naming : capitalised variable name in snake_case, camelCase for values

import { EventBase } from "analytics/types";
import { Check } from "flows/Inventory/shared/models/check/types";
import { MapDiscriminatedUnion } from "utils/tsTypesHelpers";

/**
 *
 *
 * ========== Page events ============
 *
 *
 */

// page names
export enum PageName {
  ACTIVITIES_PAGE = "activities",
  LAYOUT = "layout",
  HUB_AUTHENTICATION_PAGE = "hub_authentication",
  USER_IDENTIFICATION_PAGE = "user_identification",
  NEW_ORDER_FOR_PICKING_PAGE = "new_order_for_picking",
  PICKING_PAGE = "picking",
  PICKING_INCOMPLETE_PAGE = "picking_incomplete",
  MANUAL_PICKING_PAGE = "manual_picking",
  INBOUND_SELECT_DELIVERY = "select_delivery",
  INBOUND_PAGE = "list_preparation",
  INBOUND_DROPPING_PAGE = "dropping_list",
  INBOUND_PAGE_SHARED_LIST = "inbound/shared-list",
  INBOUND_PAGE_CLAIMED_LIST = "inbound/claimed-list",
  INVENTORY_PAGE = "inventory",
  INVENTORY_PAGE_PRIVATE_LIST = "inventory/restocking-list",
  INVENTORY_PAGE_PUBLIC_LIST = "inventory/restocking-list/public-list",
  INVENTORY_PAGE_MOVE_ITEMS = "inventory/restocking-list/move-items",
  INVENTORY_FRESH_CHECK_LIST_PAGE = "inventory/fresh-check-list",
  INVENTORY_BBD_CHECK_LIST_PAGE = "inventory/bbd-check-list",
  INVENTORY_STOCK_CHECK_LIST_PAGE = "inventory/stock-check-list",
  INVENTORY_BBD_DECLARE_EXPIRED = "outbound_product_bbd",
  INVENTORY_BBD_DECLARE_CLOSEST_BBD = "expiration_date_bbd",
  INVENTORY_EOY_CHECK_LIST_PAGE = "inventory/eoy-checks",
  INVENTORY_RECOUNT_CHECK_LIST_PAGE = "inventory/recount-check",
  TASK_PAGE = "task",
  STOCK_CHANGE_PAGE = "stock_change",
  BBD_CORRECTION_THIS_DATE_CANT_BE_EDITED = "this_date_cant_be_edited",
  HUB_ACTIVITIES = "hub_activities",
  DELIVERY_CHECK_IN = "delivery_check_in",
  STOCK_CHECK = "stock_check",
}

/**
 *
 *
 * ========== Login events ============
 *
 *
 */

type AuthActionType = "staff" | "hub";
export type AuthLogoutActionMethod = "manual" | "automatic";
export type IdentifierMethodType = "qr_scan" | "manual";

/**
 *
 *
 * ========== Track events ============
 *
 *
 */

export interface TrackEventBase extends EventBase {
  name: string;
}

/**
 * Sent whenever the application is opened.
 *
 * @type ApplicationOpenedEventType
 */
export interface ApplicationOpenedEventType extends TrackEventBase {
  name: "applicationOpened";
}

/**
 * Sent whenever the application is backgrounded.
 *
 * @type ApplicationBackgroundedEventType
 */
export interface ApplicationBackgroundedEventType extends TrackEventBase {
  name: "applicationBackgrounded";
}

/**
 * Sent whenever an error is shown.
 *
 * @type ErrorShownEventType
 * @property {string} screen_name
 * @property {string} component_value
 */
export interface ErrorShownEventType extends TrackEventBase {
  name: "errorShown";
  payload: {
    screen_name: PageName;
    component_value: string;
    component_content?: string;
    component_name?: string;
    component_variant?: string;
  };
}

/**
 * Sent whenever a login is successful.
 *
 * @type LoginCompletedEventType
 * @property {string} type
 * @property {string} user_id
 * @property {string} hub_slug
 */
export interface LoginCompletedEventType extends TrackEventBase {
  name: "loginCompleted";
  payload: {
    type: AuthActionType;
    hub_slug: string;
    quinyx_badge_number?: string;
    employee_id?: string;
    identifier_method?: IdentifierMethodType;
  };
}

/**
 * Sent whenever a logout is successful.
 *
 * @type LogoutCompletedEventType
 * @property {string} type
 * @property {string} user_id
 * @property {string} hub_slug
 */
export interface LogoutCompletedEventType extends TrackEventBase {
  name: "logoutCompleted";
  payload: {
    type: AuthActionType;
    hub_slug: string;
    quinyx_badge_number?: string;
    employee_id?: string;
    method: AuthLogoutActionMethod;
  };
}

/**
 * Sent whenever a button is clicked.
 *
 * @type InAppMessageShownEventType
 * @property {string} screen_name
 * @property {string} component_name
 * @property {string} component_value
 */
export interface InAppMessageShownEventType extends TrackEventBase {
  name: "inAppMessageShown";
  payload: {
    screen_name: PageName;
    component_name?: string;
    component_content?: string;
  };
}

/**
 * Sent whenever a button is clicked.
 *
 * @type ClickEventType
 * @property {string} screen_name
 * @property {string} component_name
 * @property {string} component_value
 */
export interface ClickEventType extends TrackEventBase {
  name: "click";
  payload: {
    screen_name: PageName;
    component_name?: string;
    component_value?: string;
    component_content?: string;
    component_variation?: string;
  };
}

export interface OrderProgressedEventType extends TrackEventBase {
  name: "orderProgressed";
  payload: {
    action: string;
    product_sku: string | null;
    ean: (string | null)[] | null;
    order_number: string;
    order_id: string;
    quantity: number;
    method?: string;
    reason?: string | null;
  };
}

export interface OrderStateUpdatedEventType extends TrackEventBase {
  name: "orderStateUpdated";
  payload: {
    state: "picked" | "packed" | "picking_finished" | "offered";
    origin: "PICKING_SCREEN" | "QR_CODE" | null;
    order_number?: string | null;
    order_id?: string;
  };
}

export interface ContainerAssignedEventType extends TrackEventBase {
  name: "containerAssigned";
  payload: {
    type: "Container" | "Shelf";
    method: "scanned" | "manual";
    container_ids: (string | null)[];
    order_number?: string | null;
    order_id?: string;
  };
}

export interface ContainerAssignmentSkippedEventType extends TrackEventBase {
  name: "containerAssignmentSkipped";
  payload: {
    type: "Container" | "Shelf";
    order_number?: string | null;
    order_id?: string;
  };
}

export interface StockCheckStartedEventType extends TrackEventBase {
  name: "stockCheckStarted";
  payload: {
    /**
     * If the check performed is an automatically-generated check then type = automated
     * If the check performed is a manual check (from stock correction flow) then type = manual
     */
    type: "automated" | "manual";
    check_id: Check["checkId"];
    product_sku: Check["sku"];
    origin: PageName;
  };
}

export interface StockCheckFinishedEventType extends TrackEventBase {
  name: "stockCheckFinished";
  payload: {
    /**
     * If the check performed is an automatically-generated check then type = automated
     * If the check performed is a manual check (from stock correction flow) then type = manual
     */
    type: "automated" | "manual";
    check_id: Check["checkId"];
    product_sku: Check["sku"];
    quantity_expected: number;
    quantity_counted: number;
    quantity_expired: number;
    quantity_damaged: number;
    quantity_tgtg: number;
    next_expiration_date?: string;
  };
}

// ===== Collaborative Inbounding =====

export enum CollaborativeInboundUpdateState {
  ListPreparationStarted = "list_preparation_started",
  ListPreparationFinished = "list_preparation_finished",
  DroppingListStarted = "dropping_list_started",
  DroppingListFinished = "dropping_list_finished",
  DroppingListUnclaimed = "dropping_list_unclaimed",
  ListVerificationStarted = "list_verification_started",
}
export enum CollaborativeInboundProgressedAction {
  ProductAddedToList = "product_added_to_list",
  ProductUpdatedQuantity = "product_updated_quantity",
  ProductRemovedFromList = "product_removed_from_list",
  ProductDropped = "product_dropped",
}

export enum CollaborativeInboundProgressedMethod {
  Scanned = "scanned",
  TextSearched = "text_searched",
  AdvancedSearched = "advanced_searched",
  ManualSU = "manual_su",
  ManualHU = "manual_hu",
}

export enum CollaborativeInboundStage {
  ListPreparation = "list_preparation",
  ListDropping = "list_dropping",
}

// ===== Inbounding events =====

export type InboundUpdateState =
  | "list_preparation_started"
  | "dropping_list_started"
  | "dropping_list_finished"
  | "list_verification_started"
  | "activity_started";

export interface InboundStateUpdated extends TrackEventBase {
  name: "inboundStateUpdated";
  payload: {
    state: InboundUpdateState | CollaborativeInboundUpdateState;
    dropping_list_id: string | null;
    sscc: string | null;
    inbounding_type: string | null;
    is_manual_unclaim?: boolean;
  };
}

export type InboundProgressedAction =
  | "product_added_to_list"
  | "product_updated_quantity"
  | "product_removed_from_list"
  | "product_dropped";

export type InboundProgressedMethod =
  | "scanned"
  | "text_searched"
  | "advanced_searched"
  | "manual_su"
  | "manual_hu";

export interface InboundProgressed extends TrackEventBase {
  name: "inboundProgressed";
  payload: {
    dropping_list_id: string;
    product_sku: string;
    action: InboundProgressedAction | CollaborativeInboundProgressedMethod;
    is_handling_unit: boolean;
    method?: InboundProgressedMethod;
    quantity: number;
    sscc: string | null;
    shelf_number: string | null;
    temporary_handling_unit_size?: number;
    dropping_list_name?: string | null;
    inbounding_stage?: CollaborativeInboundStage;
  };
}

export type StockCorrectionsOrigin = "scanned_product" | "scanned_shelf" | "search_bar";
export type StockCorrectionsScreenName =
  | "stock_correction_multiple_results"
  | "stock_correction_screen";
export interface StockCorrectionsStarted extends TrackEventBase {
  name: "stockCorrectionStarted";
  payload: {
    product_sku: string;
    shelf_number: string;
    origin: StockCorrectionsOrigin;
    screen_name: StockCorrectionsScreenName;
  };
}

export interface StockCorrectionsFinished extends TrackEventBase {
  name: "stockCorrectionFinished";
  payload: {
    product_sku: string;
    shelf_number: string;
    quantity_before_correction: number;
    quantity_expired: number;
    quantity_damaged: number;
    quantity_corrected: number;
    quantity_tgtg: number;
    quantity_after_correction: number;
    screen_name: StockCorrectionsScreenName;
  };
}

export type OutboundProgressedAction = "product_added_to_outbound" | "product_outbounded";

export interface OutboundProgressed extends TrackEventBase {
  name: "outboundProgressed";
  payload: {
    dropping_list_id: string;
    product_sku: string;
    action: OutboundProgressedAction;
    quantity_expired: number;
    quantity_damaged: number;
    quantity_perished: number;
    quantity_received: number;
    sscc: string | null;
    shelf_number: string | null;
  };
}

export type InboundVerificationReason =
  | "quantity_equal_to_one"
  | "quantity_more_than_fifty"
  | "quantity_handling_unit_mismatch";

export interface InboundVerificationProgressed extends TrackEventBase {
  name: "inboundVerificationProgressed";
  payload: {
    dropping_list_id: string;
    sscc: string | null;
    product_sku: string;
    verification_reason: InboundVerificationReason;
    quantity_before_verification: number;
    quantity_after_verification: number;
    running_number: number;
  };
}

// ===== Bbd correction events =====
export type BbdDateCorrectionOrigin = "scanned_product" | "search_bar";
export interface BbdDateCorrectionStartedEventType extends TrackEventBase {
  name: "bbdDateCorrectionStarted";
  payload: {
    product_sku: string;
    shelf_number: string;
    origin: BbdDateCorrectionOrigin;
  };
}

export interface BbdDateCorrectionFinishedEventType extends TrackEventBase {
  name: "bbdDateCorrectionFinished";
  payload: {
    product_sku: string;
    next_expiration_date_before: string;
    next_expiration_date_after: string;
  };
}

export interface HubActivityStarted extends TrackEventBase {
  name: "hubActivityStarted";
  payload: {
    screen_name: PageName.HUB_ACTIVITIES;
    category_type: string;
    hub_activity_id: string;
  };
}

type HubActivityFinishedOrigin = "origin_activity_popup" | "activity_flow";
export interface HubActivityFinished extends TrackEventBase {
  name: "hubActivityFinished";
  payload: {
    screen_name: PageName;
    origin: HubActivityFinishedOrigin;
    category_type: string;
    hub_activity_id: string;
    task_completion_time_seconds: number;
  };
}

type RestockingState =
  | "restocking_list_preparation_started"
  | "restocking_list_started"
  | "restocking_list_finished";

export interface RestockingStateUpdated extends TrackEventBase {
  name: "restockingStateUpdated";
  payload: {
    restocking_list_id: string;
    state: RestockingState;
  };
}

type RestockingProgressedAction =
  | "product_added_to_list"
  | "product_updated_quantity"
  | "product_removed_from_list"
  | "product_restocked";

export const RESTOCKING_PROGRESSED_METHOD = {
  SCANNED_PRODUCT: "scanned_product",
  TEXT_SEARCHED: "text_searched",
  SCANNED_SHELF: "scanned_shelf",
  PRE_LOADED_LIST: "pre_loaded_list",
} as const;

export type RestockingProgressedMethod =
  typeof RESTOCKING_PROGRESSED_METHOD[keyof typeof RESTOCKING_PROGRESSED_METHOD];

export interface RestockingProgressed extends TrackEventBase {
  name: "restockingProgressed";
  payload: {
    restocking_list_id: string;
    action: RestockingProgressedAction;
    method?: RestockingProgressedMethod;
    product_sku: string;
    shelf_number?: string;
    quantity?: number;
  };
}

export type TrackEventTypes =
  | ApplicationOpenedEventType
  | ApplicationBackgroundedEventType
  | LoginCompletedEventType
  | LogoutCompletedEventType
  | ClickEventType
  | OrderProgressedEventType
  | OrderStateUpdatedEventType
  | ErrorShownEventType
  | ContainerAssignedEventType
  | ContainerAssignmentSkippedEventType
  | StockCheckStartedEventType
  | StockCheckFinishedEventType
  | InAppMessageShownEventType
  | InboundStateUpdated
  | StockCorrectionsStarted
  | StockCorrectionsFinished
  | InboundProgressed
  | InboundProgressed
  | OutboundProgressed
  | InboundVerificationProgressed
  | BbdDateCorrectionStartedEventType
  | BbdDateCorrectionFinishedEventType
  | HubActivityStarted
  | HubActivityFinished
  | RestockingStateUpdated
  | RestockingProgressed;

export type TrackEventTypesMap = MapDiscriminatedUnion<TrackEventTypes, "name">;
