import { useCallback } from "react";

import shallow from "zustand/shallow";

import { BOTTOM_TAB_BAR_HEIGHT_NUMBER } from "ui/BottomTabBarContainer";

import { useInboundStore } from "../stores/inboundStore/useInboundStore";
import { EndDroppingProcessButton } from "./EndDroppingProcessButton";

export function AddItemsToSharedListButton() {
  const { inboundUIState, setInboundUIState } = useInboundStore(
    (state) => ({
      inboundUIState: state.inboundUIState,
      setInboundUIState: state.setInboundUIState,
    }),
    shallow,
  );

  const onClickAddItemsToSharedList = useCallback(() => {
    setInboundUIState({ isAddToSharedListModalVisible: true });
  }, [setInboundUIState]);

  return (
    <EndDroppingProcessButton
      shouldShowButton={!inboundUIState.isAddToSharedListModalVisible}
      onClickButton={onClickAddItemsToSharedList}
      labelMessageId="pages.inbound.add-items-to-shared-list.button"
      bottom={BOTTOM_TAB_BAR_HEIGHT_NUMBER}
    />
  );
}
